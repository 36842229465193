app-lead-gen {
  .lead-gen {
    ::-webkit-input-placeholder {
      color: $colorAccentLightGray;
    }
    ::-moz-placeholder {
      color: $colorAccentLightGray;
    }
    ::-ms-placeholder {
      color: $colorAccentLightGray;
    }
    ::placeholder {
      color: $colorAccentLightGray;
    }
    .placeholder:first-child {
      color: $colorAccentLightGray;
    }
    select option:first-child {
      color: $colorAccentLightGray;
    }
    .form.-error {
      display: block;
      position: initial;
      font-size: 12px;
    }
    select.error-border {
      border: 1px solid $colorAccentRed;
    }
    select {
      color: var(--drop-down-font-color);
      width: 100%;
      border: 0.0625rem solid var(--input-border-color);
      padding-bottom: 6px;
      outline: none;
      background-color: var(--input-background-color);
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 36px;
      padding: 12px;
      font-size: 14px;
      border-radius: 5px;
      height: 45px;
    }
    .form.-question label {
      margin-bottom: 0px;
      margin-top: 18px;
    }
    .span {
      margin-bottom: 6px;
      margin-top: 18px;
      display: block;
    }
    &.-masthead {
      padding-top: 140px;
      padding-right: 18%;
      padding-left: 18%;
    }

    h2:first-of-type {
      margin-bottom: 0;
    }

    h2:last-child {
      margin-top: 0;
    }

    &.-cols {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &.-row {
      width: 100%;
    }

    &.-submit-button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: spacer(4);
    }

    &.-submit-button {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.4375rem;
      background-color: var(--button-primary-background-color);
      border: $borderWidth solid var(--button-primary-border-color);
      border-radius: spacer(5);
      color: var(--button-primary-text-color);
      margin: 0 spacer(2) spacer(3) 0;
      user-select: none;
      display: inline-block !important;
      transition: 0.3s;

      padding: calc(#{spacer(3)} - #{$borderWidth}) calc(#{spacer(6)} - #{$borderWidth});

      &:hover {
        background-color: var(--button-primary-background-hover-color);
        color: var(--button-primary-text-hover-color);
        cursor: pointer;
        a {
          color: var(--button-primary-text-hover-color);
        }
        icon {
          color: var(--button-primary-text-hover-color);
        }
      }
    }

    &.-filler {
      margin-bottom: 24%;
    }

    &.-masthead-confirmation {
      padding-top: 140px;
    }

    &.-confirmation {
      height: 0;
      overflow: hidden;
    }

    &.-failure {
      color: $colorAccentRed;
      text-align: center;
    }
    &.-question {
      width: 45%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .-main-padding {
    padding: spacer(7) 18% spacer(9) 18%;
  }

  label {
    margin: 0;
  }

  .-asterisk label .-fontMedium::before {
    content: '*';
    color: var(--input-mandatory-color);
  }
}

@media (min-width: $screen-sm-max) {
  app-lead-gen {
    .lead-gen {
      &.-resize-full-width {
        width: 95%;
        .-phone-width {
          .-question {
            width: 47%;
          }
        }
      }

      &.-resize-width {
        width: 95%;
        .-question {
          width: 47.6%;
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  app-lead-gen {
    .lead-gen {
      &.-masthead {
        padding-top: spacer(7);
        padding-right: 18%;
        padding-left: 18%;
        display: inline-block;
      }
      &.-cols {
        display: block;
      }
    }
  }
}
