.-breadcrumb {
  display: inline-block;
  border-radius: spacer(4);
  padding: spacerThree(1) spacerThree(5);
  background: var(--masthead-tag-bg-color);
  border: 1.5px solid var(--masthead-tag-border-color);
  font-family: Source Sans Pro;
  font-size: spacerThree(5);
  font-style: normal;
  font-weight: 600;
  line-height: spacerThree(7);
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: spacer(6);
  color: var(--masthead-tag-text-color);
}

.-gradient-image {
  position: absolute;
  top: 0;
  left: 0;
}
.-gradient-image-container {
  position: relative;
  padding-top: 630px;
  height: 0;
}
app-homepage {
  .-homepage-container {
    max-width: 1536px;
    margin: 0 auto;
  }
  h1 {
    margin-top: 0;
    margin-bottom: spacer(5);
    color: $colorWpPrimaryHeadTextColor;
  }
  .-content {
    padding-left: spacer(132);
    padding-right: spacer(132);
  }

  .-clients-section {
    margin: 0 auto;
    padding: spacer(60) spacer(132) 0;
    .grid {
      display: grid;
      padding: 0;
      grid-template-columns: repeat(5, 20fr);
      > div {
        display: grid;
        align-items: center;
        max-height: 140px;
        &::before {
          content: '';
          display: block;
          grid-area: 1 / 1 / 2 / 2;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: none;
          max-height: inherit;
        }
      }
    }

    h4 {
      margin: 0;
      font-weight: 600;
      line-height: spacer(5);
      font-size: $font-size;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      color: $colorWpPrimaryHeadTextColor;
    }
  }

  .-welcome-section {
    masthead-gradient {
      position: relative;
      padding: 0px 132px;
    }
  }

  .-business-section {
    margin-top: spacer(4);
  }

  .-sandbox-section {
    margin-top: spacer(60);
  }

  .-features-section {
    margin-top: spacer(60);
  }

  .-testimonial-section {
    margin-top: spacer(60);
  }

  .-apis-section {
    card-product {
      .grid {
        margin: 0;
      }
    }
    h2 {
      text-transform: unset;
      line-height: 2rem;
      margin-top: 0;
      color: var(--best-apis-section-color);
    }
  }

  .-news-section {
    margin-top: spacer(60);
    grid:first-of-type {
      .grid {
        margin-bottom: spacerThree(7);
      }
    }
  }

  .-events-section {
    margin-top: spacer(60);
    margin-bottom: spacer(96);
  }

  masthead-gradient {
    .img-container {
      @extend .-gradient-image-container;
      img {
        @extend .-gradient-image;
      }
    }
  }

  .-last-section {
    margin-top: spacer(60);
    margin-bottom: spacer(60);
  }

  .-landingpage-image {
    // position: relative;
    top: spacerThree(5);
  }

  .-fintech {
    masthead-gradient {
      position: relative;
      bottom: spacer(7);
      .text-container {
        width: 49%;
      }

      .img-container {
        width: 51%;
        @extend .-landingpage-image;
        @extend .-gradient-image-container;
        padding-top: 703px;
      }
    }
    masthead-basic {
      .text-container {
        p {
          color: $colorAccentLightCoolGrey;
        }
      }
    }
  }

  .-payfac {
    masthead-gradient {
      position: relative;
      bottom: spacer(4);
      .text-container {
        width: 50%;
      }
      .img-container {
        width: 40%;
        @extend .-landingpage-image;
        @extend .-gradient-image-container;
        padding-top: 437px;
      }
    }
  }

  .-enterprise {
    masthead-gradient {
      position: relative;
      bottom: spacer(4);
      .text-container {
        width: 49%;
      }
      .img-container {
        width: 51%;
        @extend .-landingpage-image;
        @extend .-gradient-image-container;
        padding-top: 552px;
      }
    }
    masthead-basic {
      .text-container {
        p {
          color: $colorAccentLightCoolGrey;
        }
      }
    }
  }

  .-smb {
    masthead-gradient {
      .text-container {
        width: 45%;
        padding-right: spacer(66);
      }

      .img-container {
        width: 50%;
        @extend .-gradient-image-container;
        padding-top: 696px;
      }
    }
  }

  .-iso {
    masthead-gradient {
      .text-container {
        width: 45%;
        padding-right: spacer(66);
      }

      .img-container {
        width: 50%;
        @extend .-gradient-image-container;
        padding-top: 585px;
      }
    }
  }

  .-platforms {
    masthead-gradient {
      .text-container {
        width: 50%;
      }

      .img-container {
        width: 40%;
        @extend .-landingpage-image;
        @extend .-gradient-image-container;
        padding-top: 546px;
      }
    }

    masthead-basic {
      .text-container {
        p {
          color: $colorAccentLightCoolGrey;
        }
      }
    }
  }

  section {
    &.-landingpage.-features-section {
      margin-top: spacer(60);
    }
    &.payment-figures {
      margin-top: spacer(60);
    }
  }
  @media (min-width: $breakpoint-medium-mobile) and (max-width: $screen-md) {
    .-apis-section {
      card-product {
        padding: spacer(5);
        .grid {
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          gridcolumn {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media (max-width: $breakpoint-medium-mobile-max) {
    card-product {
      padding: spacer(5);
      .grid {
        margin: 0;
        gridColumn {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .-apis-section gridcolumn {
    padding: 0 16px;
  }
  .-news-section gridcolumn {
    padding: 0 spacer(4);
  }
}
@media (max-width: $screen-md) {
  .-apis-section gridcolumn {
    margin-bottom: spacer(5);
  }
  .-news-section grid .grid gridcolumn,
  .-events-section grid .grid gridcolumn {
    margin-bottom: spacer(5);
  }
}

@media (max-width: $screen-mobile-xs-max) {
  app-homepage {
    .-content {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
}

@media (max-width: $screen-sm-max) {
  app-homepage {
    .-content {
      padding-left: spacerThree(8);
      padding-right: spacerThree(8);
    }

    .-welcome-section {
      masthead-gradient {
        padding: 0px spacer(5);
      }
    }

    .-clients-section {
      margin-top: spacer(60);
    }

    convergence-masthead {
      .text-container {
        padding: 0 spacerThree(7);
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
  .-sandbox-section {
    masthead-basic {
      padding: 0px spacer(5) !important;
    }
  }
}

@media (min-width: $screen-md-min) {
  masthead-basic {
    .text-container {
      padding-left: spacer(132);
    }
  }
}

@media (max-width: $breakpoint-big-mobile) {
  .-clients-section {
    .grid {
      grid-template-columns: repeat(2, 50fr) !important;
      div {
        margin: 0 auto;
        width: 150px;
        height: 136px;
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-lg) {
  .-payfac {
    masthead-gradient {
      .img-container {
        padding-top: 42% !important;
      }
    }
  }
}

@media (min-width: $screen-mobile-large) and (max-width: $screen-mobile-large-max) {
  .-clients-section {
    .grid {
      grid-template-columns: repeat(2, 50fr) !important;
      div {
        margin: 0 auto;
        width: 260px;
        height: 136px;
      }
    }
  }
}
@media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
  .-clients-section {
    .grid {
      grid-template-columns: repeat(3, 33fr) !important;
      div {
        margin: 0 auto;
        width: 230px;
        height: 136px;
      }
      div:last-child {
        display: none;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  app-homepage {
    .-news-section,
    .-events-section {
      h1 {
        &.-fontDisplayXSmall {
          font-size: 24px;
        }
      }
    }
    .-sandbox-section {
      h1 {
        font-size: 24px;
        &.-fontDisplayMedium {
          font-size: 24px;
        }
      }
    }

    masthead-gradient {
      .img-container {
        @extend .-gradient-image-container;
        padding-top: 82%;
      }
    }
    .-enterprise {
      masthead-gradient {
        .img-container {
          @extend .-gradient-image-container;
          padding-top: 70%;
        }
      }
    }
    .-fintech {
      masthead-gradient {
        .img-container {
          @extend .-gradient-image-container;
          padding-top: 89%;
        }
      }
    }
    .-payfac {
      masthead-gradient {
        .img-container {
          @extend .-gradient-image-container;
          padding-top: 82%;
        }
      }
    }
    .-platforms {
      masthead-gradient {
        .img-container {
          @extend .-gradient-image-container;
          padding-top: 70%;
        }
      }
    }
    .-smb {
      masthead-gradient {
        .img-container {
          top: spacerThree(10);
          @extend .-gradient-image-container;
          padding-top: 90%;
        }
      }
    }
    .-iso {
      masthead-gradient {
        .img-container {
          top: spacerThree(10);
          @extend .-gradient-image-container;
          padding-top: 75%;
        }
      }
    }
  }
}
