.form {
  &.-question {
    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: spacer(5);
      margin-top: spacer(4);
      position: relative;

      span {
        width: 100%;
        line-height: 24px;
        margin-bottom: spacer(2);

        &.-disabled-label {
          color: var(--body-text-color);
        }
      }

      input {
        @extend .question-input;

        &.-disabled {
          pointer-events: none;
          color: var(--input-text-color);
          border-color: $colorSupportingLightGray;
          background-color: var(--input-background-color);

          &:focus {
            border-color: $colorSupportingLightGray;
          }
        }
      }

      textarea {
        @extend .question-input;
        resize: both;
        // min-height: 43px;
        overflow: hidden;
        padding: spacer(2) spacer(2) spacer(2) spacer(3) !important;
        min-height: spacer(9) !important;
      }
    }
  }

  &.-error {
    // position: absolute;
    color: $colorAccentRed;
    margin-bottom: 0 !important;
    margin-top: spacer(2) !important;
    bottom: -24px;
  }

  &.-help {
    opacity: 0.5;
    display: block;
  }
}

.question-input {
  width: 100%;
  border: 0.0625rem solid var(--input-border-color);
  padding-bottom: 6px;
  outline: none;
  background-color: var(--input-background-color);
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: spacer(6);
  padding: spacer(3);
  font-size: 14px;
  border-radius: 5px;
  height: 45px;

  &:focus {
    border: 0.0625rem solid var(--input-focus-color);
  }

  &.error {
    border: 1px solid $colorAccentRed;
    color: var(--input-mandatory-color);
  }

  &::placeholder {
    color: var(--login-input-text-color);
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--login-input-bg-color) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: var(--input-text-color);
}

@media (max-width: $screen-sm-max) {
  .question-input {
    // width: 80%;
  }
}
