masthead-centered {
  color: $colorSupportingWhite;

  .masthead-centered {
    position: relative;

    icon {
      display: block;
    }

    &.-container {
      background-image: unset !important;
      background-color: var(--login-image-bg-color);
      background-position: center;
      background-size: cover;
      // height: 50rem;
      text-align: left;
      // container-fluid
      // padding-right: 15px;
      // padding-left: 15px;
      margin-right: auto;
      margin-left: auto;

      .row {
        width: 100%;
      }
    }

    &.-buttonScrollDown {
      left: calc(50% - #{spacer(6)});
      position: absolute;
      bottom: -2.5rem;
      z-index: 2;
      display: none;
    }

    &.-image {
      height: 528px;
      position: absolute;
      right: 183px;
      top: spacer(5);
    }
  }

  .-home {
    min-height: 100vh;
  }
  .-no-padding {
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: 100px;
  }
}

@media (max-width: $screen-sm-max) {
  masthead-centered {
    display: none;
    .masthead-centered {
      .-no-padding {
        // height: 42vh;
        width: 100%;
      }
      &.-buttonScrollDown {
        display: block;
      }

      // &.-contentContainer {
      //   margin: spacer(9) 0 spacer(9) 0;
      // }

      &.-image {
        display: none;
      }
    }
  }
}
