accordion-convergence {
  .accordion-convergence {
    &.-main {
      border-bottom: $hairline solid rgba($color: $colorSupportingWhite, $alpha: 0.2);
      display: block;
      padding: spacer(3) 0;
    }

    &.-title,
    &.-subtitle {
      margin-left: spacer(6) !important;
    }
    &.-title{
      font-size: 16px !important;
      font-weight: 700 !important;
      line-height: 20px !important;
    }
    &.-subtitle {
      padding-top: spacer(3) !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
    }
    &.-title,
    &.-date {
      margin-bottom: 0 !important;
      margin-top: $hairline;
    }

    &.-date {
      margin-left: spacer(6);
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }

    &.-hiddenRow {
      overflow: hidden;
    }

    &.-hiddenContent {
      margin-top: spacer(3);
      margin-left: spacer(3);
      font-size: 16px;
      line-height: spacer(5);
      font-weight: 400;
      ul {
        li {
          padding-left: 0px !important;
          margin: 0px !important;
        }
        li::marker {
          color: $colorSupportingWhite !important;
        }
      }
    }

    &.-icon {
      background-color: var(--accordion-convergence-icon-background-color);
      cursor: pointer;
      svg > * {
        fill: var(--accordion-convergence-icon-svg-color);
      }
    }
  }

  @media (min-width: $screen-md) {
    .accordion-convergence {
      &.-date {
        margin-left: 0;
        margin-right: spacer(3);
        text-align: right;
      }
    }
  }
  @media (max-width: ($screen-sm - 1)) {
    .accordion-convergence {
      position: relative;
      &.-title{
        margin-top: spacer(4);
        width: auto;
        text-align: left;
      }
      &.-date {
        position: absolute;
        top: -23px;
        right: 14px;
        width: auto;
        text-align: right;
      }
    }
  }
}
