app-doc {
  background-color: var(--doc-page-bg-color);
  display: flex;
  flex-direction: column;
  .-docpage-content {
    display: flex;
    .-left-pane {
      flex: 1;
    }
    .-pane-width {
      width: 940px;
    }
    .-right-pane {
      width: 200px;
      padding-left: spacer(3);
      doc-index {
        top: 172px;
      }
    }
    .-full-width {
      width: 100%;
    }
    content-viewer {
      app-link {
        a {
          img {
            display: inline-block;
          }
        }
      }
      ul,
      ol {
        padding-inline-start: 20px;
        margin-top: spacer(4);
        li::marker {
          color: var(--list-color);
        }
      }

      accordion {
        ul,
        ol {
          li::marker {
            color: $colorPrimaryGreen;
          }
        }
      }

      .-padding-element:first-of-type {
        padding: spacer(42) 0 0 0;
      }

      .-padding-element {
        color: var(--body-text-color);
        padding: spacer(42) 0 0 0;
        p:first-of-type {
          margin-top: spacer(4);
        }
        p.-base-urls:first-of-type {
          margin-top: spacerThree(7);
        }

        a {
          // color: $colorAccentLightBlue;

          icon.-colorPrimaryGreen svg > * {
            fill: var(--workflow-step-expan-icon-color);
          }
        }
        workflow-card,
        workflow-card-convergence {
          a {
            color: var(--workflow-step-text-color-default);
          }
          .workflow-card {
            p {
              margin-top: 0;
            }
          }
        }

        tab-container {
          ul {
            padding-left: 0;
            margin-top: spacerThree(3);
            li {
              margin-top: 0;
              padding-left: 0;
            }
          }
          .tab-content {
            padding-left: 0;
            padding-top: 0;
          }
        }
        .engineTable {
          overflow: auto;
        }
        table {
          border-collapse: unset;
          border: $hairline solid var(--table-border-color);
          th {
            background-color: var(--table-row-bg-color);
            color: var(--table-head-text-color);
            padding: spacer(5);
            border: $hairline solid var(--table-border-color);
            border-left: 0;
            border-top: 0;
            border-right: 0;
            p {
              color: var(--table-row-text-color);
            }
          }

          td {
            color: var(--table-row-text-color);
            padding: spacer(5);
            border-bottom: $hairline solid var(--table-border-color);
            vertical-align: baseline;
          }

          tr:last-child {
            td {
              border-bottom: 0;
            }
          }
        }

        table.-addlocation-boardingfields,
        .-addlocations-settlements,
        .-send-request-table,
        .-process-refund-request-table,
        .-process-authorization-request-table,
        .-process-sale-request {
          th:first-of-type {
            width: spacer(156);
          }
          td:first-of-type {
            width: spacer(156);
          }
        }

        table.-beneficial-ownership-table,
        .-addlocations-endpoints {
          th:first-of-type {
            width: spacer(156);
          }

          th:last-of-type {
            width: spacer(156);
          }

          th {
            width: 440px;
            text-align: left;
          }

          td:first-of-type {
            width: spacer(156);
          }

          td:last-of-type {
            width: spacer(156);
          }

          td {
            width: 440px;
          }
        }

        table.-pass-updater-status {
          th:first-of-type {
            width: spacer(174);
          }

          th:last-of-type {
            width: 594px;
          }
        }

        table.-ownership-table {
          width: 1100px;
          th:last-of-type {
            width: spacer(300);
          }
          td:last-of-type {
            width: spacer(300);
          }
        }

        table.-webform-saqs-table {
          th:first-of-type {
            width: spacer(170);
          }

          th:last-of-type {
            width: 580px;
          }
        }

        table.-single-row {
          td {
            border-bottom: none;
          }
        }

        cardcapability {
          link-icon {
            a {
              color: unset;
            }
            &:hover {
              a {
                color: $colorAccentLightBlue;
              }
            }
          }
        }

        cardapi {
          link-icon {
            a {
              color: unset;
            }
          }
        }

        section-collapsable-parent {
          link-icon {
            a {
              color: unset;
            }
          }
        }

        .subtitle {
          margin-top: spacerThree(3) !important;
          @extend .-fontMedium;
          color: var(--doc-page-content-subtitle);
        }
      }

      workflow-card,
      workflow-card-convergence {
        link-icon {
          a {
            color: var(--workflow-step-text-color-default);
          }
        }
      }

      h2 {
        font-size: 1.5rem;
        line-height: 1.875rem;
        font-weight: 700;
        color: $colorWpPrimaryHeadTextColor;
      }

      h3 {
        font-size: 1.25rem;
        line-height: 30px;
        font-weight: 600;
        margin-top: spacerThree(3);
      }

      h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin-top: spacerThree(3);
        color: var(--body-text-color);
      }
      card-product {
        h3 {
          line-height: 24px;
          font-size: 18px;
          margin-top: unset;
        }
      }
      .-letter-spacing {
        letter-spacing: 0.23px;
      }
      workflow-task,
      workflow-task-convergence {
        .workflowTask {
          &.-container {
            min-height: spacerThree(180);
          }
          &.-links {
            width: 230px;
          }
        }
      }
    }
  }
}

.-content-link {
  a {
    color: var(--link-color);
    display: flex;
    align-items: flex-end;

    icon {
      margin-left: spacer(2);
      bottom: 3px;
    }
    icon.-colorPrimaryGreen svg > * {
      fill: var(--link-color);
    }
    &:hover {
      text-decoration: underline;
      color: var(--link-hover-color);
    }
  }
}

// @media (max-width: $screen-sm-max) {
//   ap.p-product {
//     .product .masthead .-buttons-scroll-down {
//       left: calc(50% - 36px);
//     }
//     .product {
//       &.-content {
//         width: 100%;
//         margin-left: 0;
//         transition: 200ms;
//         padding: spacer(7) 6% 6% 6%;
//       }

//       // &.-vertical-nav-expand {
//       //   display: block;
//       //   position: fixed;
//       //   bottom: 15px;
//       //   left: 15px;
//       //   z-index: 10;
//       // }

//       &.-slide {
//         // margin-left: 2px !important;
//         overflow: hidden;
//       }

//       &.-slide-nav {
//         width: auto !important;
//         overflow: unset !important;
//       }

//       &.-vertical-nav {
//         transition: width 200ms;
//         width: 0;
//         overflow: hidden;
//       }
//     }
//   }
// }

// $verticalNavSize: 222px;

// app-product {
//   display: flex;

//   .product {
//     &.-vertical-nav {
//       display: unset;
//       z-index: 2;

//       // Remove this and uncomment next line if nav has own column
//       position: fixed;
//       // min-height: 100vh;

//       nav-vertical {
//         display: block;
//         top: 90px;
//         padding: 18px;
//         width: $verticalNavSize;
//         position: sticky;
//         bottom: 0;
//         height: calc(100vh - 90px);
//         overflow-y: auto;
//       }
//     }

//     &.-content {
//       // Uncomment next line if nav has own column
//       // width: calc(100vw - $verticalNavSize);
//       padding: 0 10% 5% calc(3% + $verticalNavSize);
//     }

//     &.-vertical-nav-expand {
//       display: none;
//     }
//   }
// }

// @media (max-width: $screen-sm-max) {
//   app-product {
//     .product {
//       &.-content {
//         width: 100%;
//         margin-left: 0;
//         transition: 200ms;
//         padding: spacer(7) 10% 6% 6%;
//       }

//       &.-vertical-nav-expand {
//         display: block;
//         position: fixed;
//         bottom: 15px;
//         left: 15px;
//         z-index: 10;
//       }

//       &.-slide {
//         // margin-left: 2px !important;
//         margin-left: $verticalNavSize;
//         overflow: hidden;
//       }

//       &.-slide-nav {
//         width: auto !important;
//         overflow: unset !important;
//       }

//       &.-vertical-nav {
//         transition: width 200ms;
//         width: 0;
//         overflow: hidden;
//       }
//     }
//   }
// }
@media (max-width: $screen-laptop-sm) {
  app-doc {
    .-docpage-content {
      .-left-pane {
        width: 100%;
      }
      .-right-pane {
        display: none;
      }
    }
  }
}

@media (max-width: $screen-mobile-small-max) {
  app-doc {
    .-docpage-content {
      content-viewer {
        .-fontDisplayXSmallHeading {
          font-size: 26px;
          line-height: 30px;
        }
      }
    }
  }
}

@media (max-width: ($screen-laptop-sm - 1)) {
  app-doc {
    .-docpage-content {
      content-viewer {
        .-padding-element {
          padding-top: spacerThree(7);
        }
      }
    }
  }
}
