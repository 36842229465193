@mixin secondaryNavUL {
  list-style-type: none;
  padding-left: spacerThree(5);
}

.header-dropdown {
  z-index: 1;
  position: absolute;
  // To fully show container corners
  padding: $hairline;
  border-radius: spacer(2);
  border: $hairline solid var(--header-mega-dropdown-border-color);
}

.header-dropdown-arrow {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  border-top: $hairline solid var(--header-mega-dropdown-border-color);
  border-left: $hairline solid var(--header-mega-dropdown-border-color);
}

.header-dropdown-transition {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  transition: all 0.3s ease 0.3s, height 0.15s ease 0.3s;
}

.header-dropdown-transition-hover {
  opacity: 1;
  max-height: 1000px;
  visibility: visible;
  transition: all 0.2s ease 0.2s, height 0.1s ease 0.2s;
}

.active-style {
  background-color: $colorShadePrimaryBlackS40;
  app-link a {
    icon {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}

ul.header-secondary-nav {
  @include secondaryNavUL();
  margin: 0;
  min-width: 350px;

  & > .-hover {
    &:first-child {
      cursor: default;

      ul {
        visibility: hidden !important;
        padding: 0 !important;
      }

      &:hover ul {
        visibility: visible !important;
        opacity: 1 !important;
        padding: spacer(5) spacer(4) !important;
      }
    }
    &:hover {
      @extend .active-style;
    }
  }

  & > li {
    display: inline-flex;
    vertical-align: top;
    position: relative;
    margin: 0;
    padding: 0 spacerThree(5);
    transition: background-color 0.35s, visibility 0;
    align-items: center;
    &.active {
      @extend .active-style;
    }

    & > app-link a {
      display: flex;
      height: 70px;
      align-items: center;
      color: #ffffff;
      icon {
        margin-left: spacer(3);
        svg {
          transition: 0.5s;
        }
      }
    }
  }

  ul {
    @include secondaryNavUL();
    position: absolute;
    top: 70px;
    left: 0;
    opacity: 0;
    transition: opacity 0.35s;
    background-color: #01181f;
    width: 340px;

    &.showing {
      opacity: 1;
    }

    li {
      display: block;
      margin: 0;

      app-link {
        width: 100%;

        a {
          color: inherit;
          padding: spacerThree(5) spacerThree(3);
          display: block;
        }
      }

      &:hover {
        background-color: #04203e;
        color: #3bcff0;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  ul.header-secondary-nav {
    padding-left: unset;
  }
}
