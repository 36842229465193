.payment-figures {
  display: flex;
  margin-top: 90px;
  .-info-block {
    width: 255px;
    .-info-heading {
      font-size: spacer(5);
      line-height: spacer(5);
      width: 195px;
    }
    .-info-text {
      font-size: spacer(3);
      line-height: spacer(3);
      font-weight: 700;
      padding-top: spacerThree(7);
    }
  }
  grid {
    width: 100%;
    .-content-div {
      padding: unset;
      text-align: center;
      padding: 0px spacerThree(5) 0px 13px;
      position: relative;
      h2 {
        font-size: spacer(66);
        line-height: spacer(66);
        margin: unset;
        color: var(--payment-figures-title-color);
      }
      p {
        font-size: 20px;
        line-height: spacer(30);
        padding: spacer(6) spacer(4) 0px spacer(4);
      }
    }

    .grid {
      padding: unset;
      margin: unset;
    }
    .-border-marker {
      background-image: url('../../assets/images/dashed-border.png');
      width: 2px;
      height: 100%;
      position: absolute;
      left: 0;
    }
  }
}

@media (max-width: $breakpoint-sm-desktop-max) {
  .payment-figures {
    width: 100%;
    display: block;
    .-info-block {
      width: 100%;
      p {
        width: 100% !important;
        text-align: center;
      }
      p.-info-text {
        padding: 0;
      }
    }
    grid {
      .grid {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
@media (max-width: $screen-xs-max) {
  .payment-figures {
    .-info-block {
      margin-bottom: spacer(5);
    }
    grid {
      .grid {
        justify-content: center;
        .-content-div {
          width: 100%;
          max-width: 344px;
          margin-left: spacer(3);
          margin-right: spacer(3);
          p {
            padding-left: unset;
            padding-right: unset;
          }
        }
      }
      gridcolumn {
        border-bottom: 1px solid $colorBorderBottomTeal;
        .-border-marker {
          display: none;
        }
        p {
          margin-bottom: spacer(4);
        }
      }
    }
  }
}
@media (min-width: $screen-sm) and (max-width: $breakpoint-sm-desktop-max) {
  .payment-figures {
    width: 100%;
    display: block;
    .-info-block {
      width: 100%;
      margin-bottom: spacer(5);
      p {
        width: 100% !important;
        text-align: left;
      }
      p.-info-text {
        padding: 0;
        text-align: left;
      }
    }

    grid {
      .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      gridcolumn:nth-child(3) .-border-marker {
        display: none;
      }
      .-content-div {
        width: 50%;
        margin-bottom: spacer(6);
        p {
          max-width: 339px;
          margin: 0 auto;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

@media (max-width: $screen-mobile-small-max) {
  .payment-figures {
    grid {
      .-content-div {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }
}
