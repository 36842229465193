app-main-nav {
  display: block;
  padding-left: spacerThree(11);
  header-menu-dropdown {
    position: relative;
  }

  .header-menu-dropdown {
    position: relative;
    padding: spacer(5);
    .-menu {
      // Use code below for actual header integration
      @extend .header-dropdown-transition;
      position: absolute;
      display: flex;
      top: 110%;
      padding: 0;
      left: -221px;
      cursor: auto;
    }
    .-custom-size-icon {
      height: 8px;
      width: 13px;
      margin-left: spacer(3);
      svg {
        use {
          fill: var(--main-nav-menu-item-menu-pulldown-color);
        }
      }
    }

    &:hover {
      .-menu {
        @extend .header-dropdown-transition-hover;
      }
    }
  }
  .link-nav {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-self: stretch;
    flex-direction: row;

    .-link-items {
      display: flex;
      align-self: stretch;
      position: relative;
      padding: spacer(5);

      a {
        display: flex;
        align-items: center;
        width: 100%;
        line-height: 1.25rem;
        color: var(--main-nav-menu-item-color);
        font-weight: 600;
        &:focus,
        &:hover {
          text-decoration: none;
        }
      }

      // a {
      //   color: var(--main-nav-menu-item-color);
      //   &:active {
      //     color: var(--main-nav-menu-item-color)-active;
      //   }

      //   &:visited {
      //     color: var(--main-nav-menu-item-color)-visited;
      //   }

      //   &:hover {
      //     text-decoration: none;
      //   }
      // }
    }
  }

  .-active {
    color: $colorAccentLightBlue;
    font-weight: 700;
  }
}

// @media (max-width: $screen-sm-max) {
//   app-main-nav {
//     .link-nav {
//       flex-direction: column;
//       margin-top: 2px;
//       border-top: $hairline solid $colorAccentDarkTeal;

//       .-link-items {
//         padding: 0;
//       }

//       a {
//         border-bottom: $hairline solid $colorAccentDarkTeal;
//         padding: spacer(5) spacer(4);
//       }
//     }
//   }
// }

@media (max-width: ($screen-laptop-sm - 1)) {
  app-main-nav {
    display: none;
    // .link-nav {
    //   flex-direction: column;
    //   margin-top: 2px;
    //   border-top: $hairline solid $colorAccentDarkTeal;

    //   .-link-items {
    //     padding: 0;
    //   }

    //   a {
    //     border-bottom: $hairline solid $colorAccentDarkTeal;
    //     padding: spacer(5) spacer(4);
    //   }
    // }
  }
}
