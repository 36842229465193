// WP colors
$colorWpPrimaryLightBlue: #285bc5;
$colorWpAccentDarkBlue: #1b1b6f;
$colorWpAccentDarkDeepBlue: #080821;
$colorWpAccentDarkDeepBlueS50: #0e0e38;
$colorWpPurple: #4c12a1;
$colorWpLightPurple: #a18cde;
$colorWpLightPurpleSecondary: #ae81ff;
$colorAccentRed: #ff1f3e;

// WP Font Colors
$colorWpPrimaryHeadTextColor: #333333;
$colorWpPrimaryDarkHeadTextColor: #fffffff2;
$colorWpPrimaryBodyTextColor: #353535;
$colorWpPrimaryBodyTextDarkColor: #f6f6f6;
$colorWpFontDark: #262626;
$colorWpBlueSaturated: #1880a2;
$colorWpWhiteShade: #d7d7d7;
$colorWpWhiteTitleShade: #f6f6f6;
$colorWpSecondaryBodyTextColor: #f6f6f6;
$colorWpSecondaryTextColor: #ffffffcc;
$colorWpTextColor: #ffffff73;

// Supporting Colors
$colorSupportingBlack: #000000;
$colorSupportingBlue: #ddecfc;
$colorSupportingRed: #fff2f1;
$colorSupportingDarkThemeRed: #57141f;
$colorSupportingDarkThemeRedLight: #f8463b;
$colorSupportingGreenLight: #e8f6f1;
$colorSupportingNotesLightBlue: #ebfafe;
$colorSupportingWhite: #ffffff;
$colorSupportingWhiteOpacity20: #ffffff33;
$colorSupportingGray: #d9d9d9;
$colorSupportingGray87: #dedede;
$colorSupportinDarkgGray: #4a4a4a;
$colorSupportingLightGray: #c2c2c2;
$colorSupportingLightGray10: #dddddd;
$colorSupportingLightGray20: #adadad;
$colorSupportingLightGray30: #aeaeae;
$colorSupportingLightBlue: #c7d6f5;
$colorSupportingGreen: #1aa871;
$colorSupportingDarkThemeGreen: #4bbc8d;
$colorSupportingDarkThemeDarkGreen: #133d3d;
$colorSupportingPurple: #4c12a1;
$colorSupportingOrange: #ff5c52;
$colorPrimaryBlack: #012834;
$colorAccentLightBlue: #3bcff0;
$colorAccentLightBlueDeSaturated: #4cc4df;
$colorAccentLightRed: $colorSupportingOrange;
$colorIconCircleGray: #a8a8a8;
$colorSupportingBlueCB: #0e0e38;
$colorAccentYellow: #ffcd00;
$colorNotificationYellow: #fffee7;
$colorAccentLightPurple: #a18cde;
$colorAccentLightOrange: #fd8d62;
$colorAccentLightWhite: #e7e7e7;
$colorAccentPurple: #4c12a1;
$colorLightBlueS30: #2991a8;
$colorAccentDarkMediumGrey: #9a9a9a;
$colorAccentDarkThemeBlue: #111f4f;
$colorAccentDarkThemeLightBlue: #3273de;
$colorAccentDarkThemeYellow: #573a12;
$colorAccentDarkThemeLightYellow: #d19600;

// Primary Colors

$colorPrimaryGreen: $colorWpAccentDarkBlue;

// Accent Colors
$colorAccentDeepBlue: #1b1b6f;
$colorAccentDarkBlue: #062f3c;
$colorAccentBlueShade: #013f5c;
$colorAccentBlueShadeHover: #0088d14d;
$colorAccentApiCardBgBlue: #003242;
$colorAccentLighterBlue: #34bad8;
$colorAccentLightBlueSecondary: #76def5;
$colorAccentLightSkyBlue: #a6d0d9;
$colorAccentLightSkyBlueSecondary: #9de7f7;
$colorAccentLightThinBlue: #e2f2f5;
$colorAccentDarkTeal: #015b7e;
$colorAccentTeal: #009775;
$colorAccentLightTeal: #1d778d;
$colorAccentCoolGray: #ebebeb;
$colorAccentLightGray: #979797;
$colorAccentWarmGray: #f9f5f1;
$colorAccentSunglow: #ffc845;
$colorAccentGreen: #009775;
$colorLightGreen: #66c1ac;
$colorLightPurple: #bdafe8;
$colorAccentLightGreen: #99d5c8;
// $colorAccentRed: #ff1f3e;

$colorAccentPink: #ffbcc5;
$colorAccentGrey: #4a4a4a;
$colorAccentDarkGrey: #dfdfdf10;
$colorAccentDarkestGrey: #2b3e47;
$colorAccentLightGrey: #dfdfdf;
$colorAccentLightCoolGrey: #f5f5f5;
$colorAccentLightCoolGreen: #b7ebb2;
$colorAccentLightCoolRed: #ff8f9f;
$colorAccentLightCoolPurple: #7e9ddc;
$colorAccentHighLightBlue: #04203e;

// Tints
$colorTintTealT70: #4db69e;
$colorTintLightRedT80: #fda481;
$colorTintPrimaryBlackT60: #677e85;
$colorTintPrimaryBlackT80: #34535e;
$colorTintPrimaryBlackT90: #1a3e48;
$colorTintPrimaryBlackT100: #0A303C;

//Shades
$colorShadePrimaryBlack: #013b4d;
$colorShadePrimaryBlackS20: #01202a;
$colorShadePrimaryBlackS40: #01181f;
$colorShadePrimaryBlackS50: #01141a;
$colorShadePrimaryBlackS60: #011f29;
$colorShadePrimaryBlackS70: #001c25;
$colorShadePrimaryBlackS90: #00171d;
$colorShadePrimaryBlackS10: #01232e;

// Font Colors
$colorFontLightGray: #ccd3d5;

//added new colors for convergence
$colorFontLightCyan: #c0cce2;
$colorFontDarkCyan: #7c8087;
$colorFontLightBlue: #d9f1f6;
$colorBorderWilliam: #3a5d68;
$colorFontGrey8: #d8d8d8;
// Icon Color
$coloIconBackground: #0d0d0d;
$colorIconInkBlue: #2864fb;
$colorCircleSvgGray: #808b8f;
$colorBorderBottomTeal: #08485c;
$colorBgDarkTeal: #023443;
$colorBgLinkHover: #03161c;
$colorCheckboxBordergrey: #eeeeee;
$colorCheckboxCheckedBorderBlue: #0088d1;

.-colorShadePrimaryBlackS40 {
  background-color: $colorShadePrimaryBlackS40;
}

$colors: (
  'colorPrimaryBlack': $colorPrimaryBlack,
  'colorPrimaryGreen': $colorPrimaryGreen,
  'colorAccentDeepBlue': $colorAccentDeepBlue,
  'colorAccentLightBlue': $colorAccentLightBlue,
  'colorAccentDarkTeal': $colorAccentDarkTeal,
  'colorAccentTeal': $colorAccentTeal,
  'colorAccentCoolGray': $colorAccentCoolGray,
  'colorAccentWarmGray': $colorAccentWarmGray,
  'colorAccentLightRed': $colorAccentLightRed,
  'colorAccentSunglow': $colorAccentSunglow,
  'colorAccentYellow': $colorAccentYellow,
  'colorAccentPurple': $colorAccentPurple,
  'colorAccentGreen': $colorAccentGreen,
  'colorLightGreen': $colorLightGreen,
  'colorLightPurple': $colorLightPurple,
  'colorAccentRed': $colorAccentRed,
  'colorAccentLightGreen': $colorAccentLightGreen,
  'colorAccentLightPurple': $colorAccentLightPurple,
  'colorAccentLightOrange': $colorAccentLightOrange,
  'colorTintTealT70': $colorTintTealT70,
  'colorTintLightRedT80': $colorTintLightRedT80,
  'colorTintPrimaryBlackT60': $colorTintPrimaryBlackT60,
  'colorTintPrimaryBlackT80': $colorTintPrimaryBlackT80,
  'colorTintPrimaryBlackT90': $colorTintPrimaryBlackT90,  
  'colorTintPrimaryBlackT100':$colorTintPrimaryBlackT100,
  'colorShadePrimaryBlackS20': $colorShadePrimaryBlackS20,
  'colorShadePrimaryBlackS40': $colorShadePrimaryBlackS40,
  'colorShadePrimaryBlackS50': $colorShadePrimaryBlackS50,
  'colorShadePrimaryBlackS60': $colorShadePrimaryBlackS60,
  'colorShadePrimaryBlackS70': $colorShadePrimaryBlackS70,
  'colorShadePrimaryBlackS90': $colorShadePrimaryBlackS90,
  'colorSupportingBlack': $colorSupportingBlack,
  'colorSupportingWhite': $colorSupportingWhite,
  'colorAccentGrey': $colorAccentGrey,
  'colorAccentDarkGrey': $colorAccentDarkGrey,
  'colorAccentDarkestGrey': $colorAccentDarkestGrey,
  'colorAccentPink': $colorAccentPink,
  //added new colors for convergence
  'colorAccentLightGrey': $colorAccentLightGrey,
  'colorAccentLightCoolGrey': $colorAccentLightCoolGrey,
  'colorAccentLightCoolGreen': $colorAccentLightCoolGreen,
  'colorAccentLightCoolRed': $colorAccentLightCoolRed,
  'colorAccentLightCoolPurple': $colorAccentLightCoolPurple,
  'colorFontLightCyan': $colorFontLightCyan,
  'colorFontDarkCyan': $colorFontDarkCyan,
  'colorIconInkBlue': $colorIconInkBlue,
  'colorCircleSvgGray': $colorCircleSvgGray,
  'colorFontLightBlue': $colorFontLightBlue,
  'colorBorderWilliam': $colorBorderWilliam,
  'colorFontGrey8': $colorFontGrey8,
  'colorAccentLightSkyBlueSecondary': $colorAccentLightSkyBlueSecondary,
  'colorBorderBottomTeal': $colorBorderBottomTeal,
  'colorBgDarkTeal': $colorBgDarkTeal,
  'coloIconBackground': $coloIconBackground,
  'colorIconCircleGray': $colorIconCircleGray,
  'colorBgLinkHover': $colorBgLinkHover,
  'colorShadePrimaryBlackS10': $colorShadePrimaryBlackS10,
  'colorCheckboxBordergrey': $colorCheckboxBordergrey,
  'colorCheckboxCheckedBorderBlue': $colorCheckboxCheckedBorderBlue,
  'colorAccentBlueShadeHover': $colorAccentBlueShadeHover,
  // ligh theme changes
  'colorWpPrimaryLightBlue': $colorWpPrimaryLightBlue,
  'colorSupportingGreen': $colorSupportingGreen,
);

@mixin colorClasses($class, $property) {
  @each $color, $value in $colors {
    &.-#{$color} {
      #{$class} {
        #{$property}: #{$value};
      }
    }
  }
}

@mixin color($property) {
  @each $color, $value in $colors {
    &.-#{$color} {
      #{$property}: #{$value};
    }
  }
}
