masthead-gradient {
  display: flex;
  flex-direction: column;
  margin: spacer(4) auto 0;
  align-items: center;

  .text-container {
    p {
      font-size: 1.125rem;
      line-height: 1.875rem;
    }
  }
  img {
    width: 100%;
    top: 30% !important;
    transform: translateY(-30%);
  }

  h1 {
    margin: 0 0 spacerThree(12);
    text-transform: none;
    color: var(--masthead-title-color);
    &.-fontDisplayMedium {
      font-weight: 400;
    }
  }

  p {
    margin-bottom: spacerThree(9);
  }

  link-icon {
    font-weight: 600;
  }

  .masthead-gradient-background {
    position: absolute;
  }

  @media (max-width: $screen-sm-max) {
    img {
      width: 100%;
      margin: 0;
    }
    .text-container {
      margin-top: spacer(4);
    }
  }
  @media (max-width: $breakpoint-big-mobile) {
    .text-container,
    .img-container {
      width: 100% !important;
    }
  }
  @media (min-width: $screen-sm) and (max-width: $breakpoint-desktop) {
    .text-container {
      width: 50% !important;
    }
    .img-container {
      overflow: hidden;
      width: 40% !important;
    }
  }
  @media (min-width: $screen-sm) {
    margin: initial;
    flex-direction: row-reverse;
    justify-content: space-between;
    .img-container {
      z-index: 2;
      width: 50%;
    }

    .text-container {
      width: 50%;
      z-index: 2;
      p {
        padding-right: spacer(102);
      }
    }
  }

  @media (max-width: $breakpoint-sm-mobile-max) {
    .text-container h1.-fontDisplayMedium {
      font-size: 32px;
      line-height: 120%;
      font-weight: 400;
    }
  }
  @media (max-width: $screen-sm) {
    .text-container,
    .img-container {
      width: 100% !important;
    }
    .img-container {
      top: 0;
    }
    .text-container {
      margin-top: 30px;
    }
  }
  @media (max-width: $breakpoint-medium-mobile-max) {
    .img-container {
      overflow: hidden;
      margin-bottom: 30px;
    }
  }
  @media (max-width: ($breakpoint-desktop - 1px)) {
    bottom: unset !important;
  }
}

@media (max-width: $screen-sm) {
  masthead-gradient {
    h1 {
      &.-fontDisplayMedium {
        font-size: 32px;
      }
    }
    .-breadcrumb {
      position: absolute;
      top: -15px;
    }
  }
}

@media (min-width: $screen-sm) {
  masthead-gradient {
    .-breadcrumb {
      position: static;
    }
  }
}
