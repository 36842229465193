docheader-secondary-nav {
  .-docheader-sec-nav {
    wpbutton {
      margin: auto 0;
    }
    &.-container {
      display: flex;
      width: 100%;
      height: 71px;
      border-bottom: 1px solid var(--doc-header-bottom-border-color);
      padding-left: spacerThree(3);
      background-color: var(--doc-header-bg-color);
      ul {
        display: flex;
        margin: 0px;
        padding: 0px;
        li {
          list-style-type: none;
          font-size: $font-size;
          margin: 0px;
          display: flex;
          padding-left: unset;
          app-link {
            line-height: 72px;
            a {
              color: var(--doc-header-link-color) !important;
              padding: spacer(5) spacerThree(5);
            }
          }
          &:hover {
            cursor: pointer;
            background-color: var(--doc-header-link-hover-bg-color);
            a {
              text-decoration: none;
            }
          }
          app-link {
            a {
              font-weight: 400;
            }
          }
        }
        li:has(a.-link-active) {
          border-bottom: 2px solid var(--doc-header-link-active-color);
          app-link {
            a {
              font-weight: 700;
            }
          }
        }
      }
      .-container-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        releasenotes-subscription {
          width: spacer(108);
          margin-right: spacer(5);
          .-subscription {
            margin-top: unset;
          }
        }
        .-link-icon {
          @extend .-fontSmall;
          width: 130px;
        }
        .-link-icon-api-key {
          @extend .-fontSmall;
          width: 200px;
        }
        app-api-versions {
          margin-top: unset;
          .version-button {
            background-color: $colorBgDarkTeal;
            border: 1px solid $colorBorderBottomTeal;
            font-weight: 600;
            font-size: $font-size;
            line-height: spacer(4);
            border-radius: 4px;
            padding: spacer(3) spacer(4);
            display: flex;
            align-items: center;
            icon {
              width: 13px;
              height: 8px;
            }
          }
        }
      }
    }
    .is-active {
      border-bottom: 2px solid $colorAccentLightBlue;
      app-link {
        a {
          font-weight: 700;
        }
      }
    }
  }
}
@media (min-width: $screen-mobile-xs) {
  docheader-secondary-nav {
    .-docheader-sec-nav {
      wpbutton {
        margin: auto 0;
        min-width: 110px;
        height: auto;
      }
      &.-container {
        ul {
          width: 90%;
        }
        .-container-right {
          width: 10%;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mid-one-mobile-max) {
  
  .-product-masthead {
    margin-top: spacer(3);
  }
  docheader-secondary-nav {
    .-docheader-sec-nav {
      &.-container {
        flex-wrap: wrap;
        height: auto;
        border-bottom: none;
        ul {
          width: 100%;
          border-bottom: 1px solid #08485c;
          li {
            width: auto;
            app-link {
              line-height: 45px;
              a {
                padding: spacerThree(5);
              }
            }
          }
        }
        .-container-right {
          justify-content: space-between;
          width: 100%;
          padding: spacerThree(5);
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mid-two-mobile-max) {
  .-product-masthead {
    margin-top: spacer(6);
  }
  docheader-secondary-nav {
    .-docheader-sec-nav {
      &.-container {
        flex-wrap: wrap;
        height: auto;
        border-bottom: none;
        ul {
          width: 100%;
          border-bottom: 1px solid #08485c;
          li {
            width: auto;
            app-link {
              line-height: 65px;
              a {
                padding: spacerThree(5);
              }
            }
          }
        }
        .-container-right {
          justify-content: space-between;
          width: 100%;
          padding: 0px 10px 0px 10px;
          releasenotes-subscription {
            padding: spacerThree(5);
          }
        }
      }
    }
  }
}
