modal {
  icon.-refresh svg > * {
    fill: var(--button-spinner-color); 
  }
  p.-colorAccentGrey {
    color: var(--modal-text-color);
  }
  
  icon svg > * {
    fill: var(--modal-icon-clor);
  }

  .fismodal {
    &.-opacityBlock {
      background-color: rgba($color: var(--modal-opacityBlock-background-color), $alpha: 0.8);
      display: block;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10 !important;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    &.-contentContainer {
      background-color: var(--sf-modal-bg-color);
      max-height: 90vh;
      max-width: 90vw;
      overflow: auto;
      padding: spacer(5) 40px;

      wpbutton {
        margin: spacer(4) spacer(2) spacer(3) 0;
      }
    }

    &.-header {
      margin-bottom: spacer(6);

      devx-icon {
        cursor: pointer;
        margin-bottom: spacer(3);
      }
    }

    &.-secondary-modal-header {
      display: block !important;
      position: relative;
      padding-top: spacer(6);
      padding-bottom: spacer(2);
    }

    &.guided-modal-header {
      padding-top: 12px;
    }

    &.-close {
      cursor: pointer;
      display: flex !important;
      justify-content: flex-end;
      svg {
        position: unset;
      }
    }

    &.-default-padding-bottom {
      padding-bottom: 3%;
    }

    &.-secondary-padding-bottom {
      position: absolute;
      top: 0;
      right: 0;
    }

    &.-backgroundSupportingWhite {
      background-color: var(--fismodal-bg-color);
      border-radius: 5px;
      padding: spacer(5);
      max-width: 30rem !important;
      width: 96%;
    }

    &.-guided-modal-width {
      max-width: 22rem !important;
    }

    &.-secondary-modal-margin {
      margin-bottom: spacer(4);
      display: block;
      align-items: center;
    }

    &.-custom-title {
      color: $colorWpPrimaryHeadTextColor;
      font-weight: 900;
      font-size: 22px;
      font-style: normal;
    }

    &.-modal-pointer {
      display: block !important;
      background-color: inherit;
      position: inherit;
    }
  }
}

@media (min-width: $screen-md) {
  modal {
    .fismodal {
      &.-contentContainer {
        border: 0.0625rem solid var(--fismodal-border-color);
        border-radius: 8px;
        max-width: 28rem;
      }
    }
  }
}
