app-redoc {
  #redoc {
    width: 100vw;
  }

  .sc-kiPvrU .hroqdq {
    font-family: 'Source Sans Pro' !important;
    border-bottom: 1px solid var(--spec-left-nav-search-bottom-color);
  }

  .jOFhcJ {
    font-family: 'Source Sans Pro' !important;
  }

  .sc-gstuGz .emrRcd {
    padding-left: 0px !important;
    text-align: left;
  }

  .sc-gstuGz .eXTKuo.put {
    color: var(--request-method-post-text-color);
    border: $hairline solid var(--request-method-post-border-color);
    background: var(--request-method-bg-color);
    width: 48px;
  }

  .sc-gstuGz .eXTKuo.post {
    color: var(--request-method-post-text-color);
    border: $hairline solid var(--request-method-post-border-color);
    background: var(--request-method-bg-color);
    width: 58px;
  }

  .sc-gstuGz .eXTKuo.get {
    color: var(--request-method-get-text-color);
    border: $hairline solid var(--request-method-get-border-color);
    background: var(--request-method-bg-color);
    width: 48px;
  }

  .sc-gstuGz .eXTKuo.patch {
    color: var(--request-method-patch-text-color);
    border: $hairline solid var(--request-method-patch-border-color);
    background: var(--request-method-bg-color);
    width: 58px;
  }

  .sc-gstuGz .eXTKuo.delete {
    color: var(--request-method-delete-text-color);
    border: $hairline solid var(--request-method-delete-border-color);
    background: var(--request-method-bg-color);
    width: 48px;
  }

  .sc-gstuGz .eXTKuo {
    font-family: 'Source Sans Pro' !important;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #01232d;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    min-width: 52px;
  }

  .cGviEC path {
    fill: var(--spec-left-nav-search-icon-color);
  }

  .dteJDQ {
    margin: 0px spacerThree(7);
    margin-top: spacerThree(5);
    background-color: var(--spec-left-nav-search-bg-color) !important;
    color: var(--spec-left-nav-search-text-color);
    text-align: center;
    padding-top: spacer(3);
  }

  .gwwTjn {
    color: var(--spec-left-nav-search-text-color);
  }

  breadcrumbs {
    margin-bottom: 20px;
    margin-top: 12px;
  }

  .redoc-wrap {
    border-top: 1px solid $colorBorderBottomTeal;
  }

  .api-versions-ul {
    margin: spacer(5) 0;
  }

  app-api-versions {
    margin: spacer(3) spacer(5) spacer(3) 0;
  }

  .CEtKZ .token.selector,
  .CEtKZ .token.attr-name,
  .CEtKZ .token.string,
  .CEtKZ .token.char,
  .CEtKZ .token.builtin,
  .CEtKZ .token.inserted {
    color: #a0fbaa !important;
  }

  .iyXcMX .token.selector,
  .iyXcMX .token.attr-name,
  .iyXcMX .token.string,
  .iyXcMX .token.char,
  .iyXcMX .token.builtin,
  .iyXcMX .token.inserted {
    color: #e6db74 !important;
  }

  .ifDxYI .token.selector,
  .ifDxYI .token.attr-name,
  .ifDxYI .token.string,
  .ifDxYI .token.char,
  .ifDxYI .token.builtin,
  .ifDxYI .token.inserted {
    color: #e6db74 !important;
  }

  .iyXcMX .token.number {
    color: $colorWpLightPurpleSecondary !important;
  }

  .ifDxYI .token.number {
    color: $colorWpLightPurpleSecondary !important;
  }

  .ifDxYI .token.property.string {
    color: var(--spec-page-editor-variable-color) !important;
  }

  .iyXcMX .token.property.string {
    color: var(--spec-page-editor-variable-color) !important;
  }
  .redoc-parent {
    display: inline-grid;
    width: 100%;

    breadcrumbs {
      margin-left: spacer(4);
    }
  }

  .menu-content {
    top: 141px !important;
    padding-top: 20px;
    height: calc(100vh - 140px) !important;
    background: var(--spec-left-nav-bg-color);
    width: 300px;
    max-width: 300px;
    border-right: 1px solid var(--spec-page-left-separator);
  }

  .loVRtV,
  .iZTWQJ {
    bottom: 120px !important;
    right: spacer(4);
    width: 36px;
    height: 36px;
    padding: 0 spacerThree(3);
    background: $colorWpPrimaryLightBlue;
  }

  .iZTWQJ svg {
    color: $colorSupportingWhite;
  }

  .jwEBSv {
    width: 16px;
  }
  .form-input-auth {
    width: unset !important;
    font-size: 1rem !important;
    width: 100% !important;
    @extend .redoc-input;
  }

  .form-input {
    width: 100% !important;
    @extend .redoc-input;
  }

  .response-header {
    font-weight: bold !important;
  }

  h3 {
    font-size: 1rem;
    color: var(--spec-page-endpoint-trial-title-color) !important;
  }

  .fYEeMm,
  .ejgidu {
    padding: 70px 0 0 0 !important;
  }

  .cjtbAK,
  .DLGij,
  .kzDJPW {
    padding-top: 20px !important;
  }

  .dtUibw,
  .fgXEJL,
  .iCCKfq {
    padding-top: 50px !important;
  }

  // ------------------
  .Dropdown-control {
    color: $colorPrimaryGreen !important;
  }

  .Dropdown-arrow {
    border-color: $colorPrimaryGreen transparent transparent !important;
  }

  .redoc-input {
    // width: inherit !important;
    border: 1px solid var(--spec-auth-input-border-color);
    padding-bottom: 6px !important;
    outline: none !important;
    background-color: var(--spec-auth-input-background-color) !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: 36px !important;
    padding: 12px !important;
    font-size: 14px !important;
    border-radius: 4px;
    height: 45px;
    color: var(--spec-auth-input-placeholder-color);
    &:focus {
      border: 0.0625rem solid var(--spec-auth-input-border-focus-color) !important;
    }

    &::placeholder {
      color: var(--spec-auth-input-placeholder-color);
    }

    &::-ms-input-placeholder {
      color: var(--spec-auth-input-placeholder-color);
    }
  }

  #redoc {
    // background-color: $colorPrimaryBlack !important;
  }

  // Right most panel at top with no content
  .kTYKTV {
    background-color: var(--spec-page-endpoint-trial-bg-color) !important;
  }

  // Right most panel with content
  .dtUibw,
  .fgXEJL,
  .iCCKfq {
    background-color: var(--spec-page-endpoint-trial-bg-color) !important;
  }

  // Helper for side nav classes
  .navBox {
    background-color: transparent !important;
    border-radius: 0.1875rem;
    margin: 0 spacer(4);
    color: var(--spec-left-nav-text-color) !important;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: spacer(3) spacer(3);

    .cLxwxL {
      width: 100% !important;
    }
  }

  .kczGBT .sc-evZas,
  .lmrTxF .sc-dIsUp,
  .cjWWix .sc-dIsUp,
  .fCgmqs .sc-eDWCr,
  .bCTehv .sc-dIsUp,
  .hLpUhH .sc-dIsUp {
    height: 1.1em;
    width: 1.1em;
  }

  .efZRCi {
    margin: spacer(2) 0;
  }

  .iOCLnY,
  .dUabJC {
    margin: 1px 0;
  }

  // Helper for side sub nav classes
  .navBoxSub {
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-weight: 400;
    margin-left: 18px;
    letter-spacing: 0.04em;
    background-color: transparent !important;
    color: var(--spec-left-nav-text-color) !important;

    &:hover {
      background-color: transparent !important;
      color: var(--spec-left-nav-active-color) !important;
    }
  }

  .bokgjv,
  .eUseeW {
    font-family: 'Source Sans Pro' !important;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    min-width: 52px;

    &.put {
      color: var(--request-method-post-text-color);
      border: $hairline solid var(--request-method-post-border-color);
      background: var(--request-method-bg-color);
      width: 48px;
    }

    &.post {
      color: var(--request-method-post-text-color);
      border: $hairline solid var(--request-method-post-border-color);
      background: var(--request-method-bg-color);
      width: 58px;
    }

    &.get {
      color: var(--request-method-get-text-color);
      border: $hairline solid var(--request-method-get-border-color);
      background: var(--request-method-bg-color);
      width: 48px;
    }

    &.patch {
      color: var(--request-method-patch-text-color);
      border: $hairline solid var(--request-method-patch-border-color);
      background: var(--request-method-bg-color);
      width: 58px;
    }

    &.delete {
      color: var(--request-method-delete-text-color);
      border: $hairline solid var(--request-method-delete-border-color);
      background: var(--request-method-bg-color);
      width: 48px;
    }
  }

  // Side navigation container
  .cTutD {
    background-color: $colorShadePrimaryBlackS20 !important;
  }

  // Side navigation box
  .cjqQLX {
    @extend .navBox;

    .cLxwxL {
      color: $colorSupportingWhite !important;
      width: 100% !important;

      &:hover {
        color: $colorPrimaryGreen !important;
      }
    }

    &:hover {
      background-color: $colorTintPrimaryBlackT80 !important;
      color: $colorAccentLightBlue !important;

      .cLxwxL {
        color: $colorPrimaryGreen !important;
      }
    }
  }

  .lmrTxF {
    @extend .navBox;

    .cLxwxL {
      color: $colorSupportingWhite !important;
      width: 100% !important;

      &:hover {
        color: $colorPrimaryGreen !important;
      }
    }

    &:hover {
      background-color: $colorTintPrimaryBlackT80 !important;
      color: $colorAccentLightBlue !important;

      .cLxwxL {
        color: $colorPrimaryGreen !important;
      }
    }
  }

  // Side navigation box visited
  .kUtIRQ,
  .fCgmqs,
  .hLpUhH,
  .kczGBT {
    @extend .navBox;

    &:hover {
      color: var(--spec-left-nav-text-hover-color) !important;
      font-weight: 700;
      border-radius: 6px;
      polygon {
        fill: var(--spec-left-nav-right-icon-hover-color) !important;
      }
    }
  }

  // Side navigation box selected
  .gcUzvG,
  .lhKPFF,
  .bCTehv {
    @extend .navBox;
    color: var(--spec-left-nav-active-color) !important;
    font-weight: 700;
    border-radius: 6px;
  }

  // Side navigation arrow svg
  .hjRNaf,
  .bnzdOX,
  .gaVoEJ,
  .fyYtoM,
  .iIQtyU,
  .gwRNbE,
  .iCKoBZ {
    transform: rotate(0deg);

    polygon {
      fill: var(--spec-left-nav-right-icon-color) !important;
    }
  }

  // Side navigation arrow svg selected
  .hRqESd,
  .ciSPej,
  .gCupfi,
  .lbZXRr {
    transform: rotate(180deg);

    polygon {
      fill: var(--spec-left-nav-right-icon-color) !important;
    }
  }

  // Side navigation sub menu
  .gpbcFk,
  .fpjOmP,
  .dLzvz,
  .emrRcd,
  .hsKNFs,
  .foNBab,
  .cRPJNm {
    @extend .navBoxSub;
  }

  // Side navigation sub menu focused
  .gSKHto,
  .gSdFlf,
  .bOcKue,
  .eCnHhM,
  .cRPJNm {
    @extend .navBoxSub;
    color: var(--spec-left-nav-active-color) !important;
  }

  // Side navigation sub menu next item
  .bLMrnV,
  .bOcKue,
  .cwRJJZ,
  .jCkSPq {
    @extend .navBoxSub;
  }

  // Side navigation bottom white line with redoc link
  .cxRqCB,
  .hqmkNn,
  .fburAk {
    display: none;
  }

  // Right pane content
  .dtUibw,
  .fgXEJL,
  .iCCKfq {
    background-color: var(--spec-page-endpoint-trial-bg-color) !important;
  }

  // Right pane content holder
  .hiuczA {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  // Right pane content holder parent
  .dtUibw,
  .fgXEJL,
  .iCCKfq {
    padding-bottom: 40px !important;
  }

  // Header text
  .dTJWQH,
  .iJdVKk {
    color: $colorSupportingWhite !important;
  }

  // Download Text
  .api-info {
    color: var(--spec-page-api-info-color) !important;

    p {
      font-family: 'Source Sans Pro', sans-serif !important;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 400;

      a {
        // Download Button
        color: var(--spec-page-download-button-color);
        border-color: var(--spec-page-download-button-border-color);
        border-radius: 4px;
        height: spacer(6);
        line-height: 1.5rem;

        &:hover {
          color: var(--spec-page-download-button-hover-color) !important;
          border-color: var(--spec-page-download-button-border-hover-color) !important;
          text-decoration: none;
        }
      }
    }
  }

  .api-info > p {
    a {
      padding-left: spacer(4);
      padding-right: spacer(4);
    }
  }

  // Link Text for description
  .fhwBUC,
  .cSfrsr {
    a {
      color: var(--spec-page-link-color) !important;

      &:hover {
        color: var(--spec-page-link-hover-color) !important;
      }
    }
  }

  // API Key Header text
  .lmMrOG,
  .eElxvP {
    color: var(--spec-page-intro-header-color) !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  // Auth description
  .auth-description {
    color: var(--spec-page-auth-description-color) !important;
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  // Sub header for category
  .iCqETH,
  .eFTcNW {
    color: var(--spec-auth-head-color) !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  // Auth description of key format
  .fhwBUC,
  .cSfrsr {
    p {
      font-family: 'Source Sans Pro', sans-serif !important;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 400;

      code {
        color: $colorAccentRed !important;
        font-family: 'Source Sans Pro', sans-serif !important;
        font-size: 14px !important;
      }
    }
  }

  // api key table
  .hiCFoG {
    padding: 6px 13px;
    color: $colorSupportingWhite !important;
    font-family: Source Sans Pro, sans-serif !important;
    .hIMzBi {
      b {
        float: left;
        min-width: 215px;
      }
      code {
        color: var(--redoc-text-color) !important;
        font-family: Source Sans Pro, sans-serif !important;
        font-size: 14px !important;
        border: none;
        padding: 0;
      }
    }
  }
  .security-details {
    tbody {
      tr {
        background-color: $colorShadePrimaryBlackS20 !important;

        th,
        td {
          border-color: $colorShadePrimaryBlackS20 !important;
          color: $colorSupportingWhite !important;
          font-family: 'Source Sans Pro', sans-serif !important;
        }
      }
    }
  }

  // Endpoint name
  .redoc-markdown {
    color: $colorSupportingWhite !important;
  }

  // Endpoint description text
  .fhwBUC,
  .cSfrsr,
  .hiCFoG {
    color: var(--spec-page-api-email-color) !important;
  }

  .kjegA {
    color: $colorSupportingWhite !important;
  }

  h5 {
    color: var(--spec-page-h5-color) !important;
  }

  // Links for endpoint details
  .ezQiUJ,
  .hwhhQg {
    color: $colorAccentLightBlue !important;
    i {
      font-style: initial;
      color: var(--spec-page-h5-text-color) !important;
      font-size: 14px;
    }
    &:hover {
      color: $colorSupportingWhite !important;
    }
    a {
      color: $colorAccentLightBlue !important;

      &:hover {
        color: $colorSupportingWhite !important;
      }
    }
  }
  .fptmbl {
    min-width: 135px;
    svg {
      fill: var(--spec-page-h5-icon-color);
    }
  }

  .ktUTZi {
    flex-direction: row;
    .fkfFYQ {
      width: 75%;
      text-overflow: ellipsis;
      border-radius: 4px;
      overflow: hidden;
      background: transparent;
      margin: 0;
      padding: 0;
    }
  }

  .eyYdAe {
    .dYAYxd {
      code {
        font-size: 14px;
        padding: 0;
        border: none;
        font-family: Source Sans Pro, sans-serif !important;
      }
    }
    .kSqeJg {
      b {
        color: $colorSupportingWhite;
      }
      code {
        font-size: 14px;
        padding: 0 0 0 10px;
        border: none;
        font-family: Source Sans Pro, sans-serif !important;
        color: $colorSupportingWhite;
      }
    }
  }

  .iNoDtm,
  .mnOGq,
  .eulAWG {
    button {
      background: unset;
      border: unset;
    }

    svg {
      height: 1.1em;
      width: 1.1em;

      polygon {
        fill: rgb(128, 128, 128);
      }
    }
  }

  // Change table color
  table {
    color: var(--spec-page-table-text-color) !important;
  }

  // Text colors for endpoint details
  .eWuLTN,
  .DmxHh {
    // border-color: $colorSupportingWhite !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: capitalize;
    font-weight: 700;
    border-bottom: none;
  }

  .sc-hSdWYo,
  .sc-cCsOjp,
  .sc-cBoqAE,
  .gfGXbG,
  .cDPDUw {
    color: var(--redoc-header-para-string-color) !important;
  }

  .kKFAHm,
  .euawtR {
    color: var(--redoc-header-para-string-color) !important;
    font-size: 0.875rem !important;
    line-height: 1.125rem !important;
    letter-spacing: 0.5px !important;
  }

  .sc-jfmDQi,
  .sc-jffHpj,
  .sc-jfvxQR {
    color: var(--spec-page-table-text-color) !important;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Source Sans Pro', sans-serif !important;
    line-height: 1.25rem;
  }

  .mnOGq button,
  .eulAWG button {
    font-size: 14px;
    font-weight: 400;
    font-family: Courier, monospace;
    line-height: 1.25rem;
    color: var(--redoc-head-text-color);
  }

  .sc-cJSrbW,
  .sc-eJocfa,
  .sc-eKszNL,
  .sc-eJDSGI {
    color: var(--redoc-header-para-string-color) !important;
  }

  .sc-hqyNC,
  .sc-lbVvki,
  .sc-gFGZVQ,
  .sc-gGvHcT,
  .sc-gGLxEB {
    color: var(--redoc-header-para-string-color) !important;
  }

  .sc-jqCOkK,
  .sc-GVOUr,
  .sc-fbIWvP,
  .sc-GhhNo,
  .sc-FRrlG {
    color: var(--redoc-header-para-string-color) !important;
  }

  .sc-TOsTZ,
  .sc-iNiQyp,
  .sc-iNWwEs,
  .sc-iOeugr {
    color: $colorAccentLightRed !important;
  }

  // Request Body Schema Expandable Table
  .icucAR,
  .kPKTxr {
    background-color: var(--spec-page-accordian-bg-color) !important;
  }

  // Start block: Request Body Schema One Of
  .eZZMfQ {
    color: $colorAccentYellow !important;
  }

  .jdXjUh {
    color: $colorSupportingWhite !important;
    background-color: $colorShadePrimaryBlackS40 !important;
    border: 0px !important;
  }

  .gYsnwL {
    color: $colorSupportingWhite !important;
    background-color: $colorAccentGreen !important;
    border: 0px !important;
  }

  // End block

  // Response title header
  .sc-bPyhqo,
  .sc-fIhvWL {
    color: $colorSupportingWhite !important;
  }
  .hFQRfH button,
  .keOPIh button {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    color: var(--redoc-text-color);
  }

  // Response type header
  .sc-kYPZxB,
  .sc-hkeOVe {
    color: $colorSupportingWhite !important;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    padding-bottom: spacer(2);
    border: 0;
  }

  // Success response
  .hwZXTt,
  .hurBbj,
  .klTMgX,
  .GIvIp {
    background-color: var(--spec-page-success-notification-background-color) !important;
    border: $hairline solid var(--spec-page-success-notification-border-color);
    border-left: 6px solid var(--spec-page-success-notification-border-color) !important;
    color: var(--spec-page-notification-text-color) !important;
    font-family: 'Courier', sans-serif;
  }

  .hwZXTt:focus,
  .GIvIp:focus {
    outline: none;
  }

  // middle pane accordin style css start here
  .sc-iemWCZ {
    display: none;
  }

  tr:first-of-type > .sc-hBMUJo,
  tr.last > .sc-hBMUJo {
    background: none;
  }

  .eJvdeH {
    border: 0;
  }

  .hvzWcB,
  .iTLMZZ,
  .beFrIL,
  .kZYqAP,
  .gDqeGV,
  .pxYkf,
  .auVrF,
  .fHkVIP,
  .fAAhni,
  .eSYquV,
  .hXDJiG,
  .eJRgQR {
    float: right;

    polygon {
      fill: var(--spec-page-notification-text-color);
    }
  }

  .sc-hBMUJo,
  .sc-jffHpj {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
  }

  .gaTxIt,
  .iupIzr {
    border-color: var(--spec-page-table-text-td-border-bottom-color);
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    padding-bottom: spacer(2);
  }

  .sc-hAsxaJ,
  .sc-hsWlPz,
  .sc-hsWlPz {
    .hvzWcB,
    .iTLMZZ,
    .gDqeGV,
    .pxYkf,
    .auVrF,
    .fHkVIP,
    .fAAhni,
    .hXDJiG {
      transform: rotateZ(0deg);
      polygon {
        fill: var(--spec-page-notification-text-color);
      }
    }

    .beFrIL,
    .kZYqAP,
    .eSYquV,
    .eJRgQR {
      transform: rotateZ(180deg);
    }

    strong {
      width: 63px;
      display: inline-flex;
      line-height: 21px;
    }

    .sc-jdAMXn,
    .sc-jcMfQk {
      p {
        font-family: 'Courier', sans-serif;
        color: var(--spec-page-notification-text-color);
      }
    }
  }

  .hwZXTt,
  .GIvIp {
    strong.kZTZUI,
    strong.bErzOu {
      color: var(--spec-page-notification-text-color);
    }
  }
  .klTMgX {
    strong.kZTZUI {
      color: $colorAccentLightGreen;
    }
  }
  .evVKyL,
  .dAGRwD {
    strong.kZTZUI,
    strong.bErzOu {
      color: var(--spec-page-notification-text-color);
    }
  }

  .sc-hAsxaJ,
  .sc-hsWlPz {
    padding: 19.51px;
  }

  .hwZXTt:focus,
  .GIvIp:focus {
    outline: none;
  }

  .sc-hzUIXc,
  .sc-cbeScs {
    border-top: 0;
    margin-top: -4px;
    margin-bottom: spacer(4);
    // padding-left: spacer(5);
  }

  .sc-hAsxaJ {
    border-radius: 0;
  }

  // middle pane accordin style css end here
  // Response fail
  .gKvVuj,
  .eSEOsh,
  .evVKyL,
  .dAGRwD {
    background-color: var(--spec-page-error-notification-background-color) !important;
    border: $hairline solid var(--spec-page-error-notification-border-color);
    border-left: 6px solid var(--spec-page-error-notification-border-color) !important;
    color: var(--spec-page-notification-text-color) !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    border-radius: 0;
  }

  .evVKyL:focus,
  .dAGRwD:focus {
    outline: none;
  }

  // Response Icon
  .hhjjYI,
  .jjozHG,
  .beFrIL,
  .etDIKE,
  .NifDa,
  .eSYquV {
    margin-right: 6px;
  }

  // API sandbox try it out parent
  .hgxMbQ {
    background-color: var(--spec-page-endpoint-trial-bg-color) !important;
  }

  .sc-gxMtzJ {
    background-color: var(--spec-page-endpoint-trial-bg-color) !important;
  }

  .sc-gzOgki,
  .sc-duzrYq,
  .jatMGW,
  .gWtHaL {
    background-color: var(--spec-page-endpoint-trial-bg-color) !important;
  }

  .cJFfBY,
  .gXhEov {
    background-color: var(--spec-page-endpoint-trial-bg-color) !important;
    padding-left: spacerThree(3);
    padding-right: spacerThree(3);
    box-shadow: none !important;
    border: none !important;
  }

  .cJFfBY:focus,
  .gXhEov:focus {
    box-shadow: none !important;
    background-color: var(--spec-page-endpoint-trial-bg-color) !important;
  }

  .label-name {
    color: var(--spec-page-endpoint-text-color) !important;
  }

  @mixin ver-common-style {
    font-size: 14px;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    border-radius: 4px;
    background-color: var(--request-method-bg-color);
  }

  //GET verb class
  .lkOssh,
  .dTKNOC {
    @include ver-common-style;
    color: var(--request-method-get-text-color);
    border: $hairline solid var(--request-method-get-border-color);
  }

  //PUT verb class
  .jQiBpY,
  .cBTrgf {
    @include ver-common-style;
    color: var(--request-method-post-text-color);
    border: $hairline solid var(--request-method-post-border-color);
  }

  //POST verb class
  .eNtypH,
  .eCLwuE {
    @include ver-common-style;
    color: var(--request-method-post-text-color);
    border: $hairline solid var(--request-method-post-border-color);
  }

  //PATCH verb class
  .bRDScN,
  .cvnKeC {
    @include ver-common-style;
    color: #fd8d62;
    border: $hairline solid #fd8d62;
  }

  //DELETE verb class
  .dOMPvF,
  .hvIPyG {
    @include ver-common-style;
    color: var(--request-method-delete-text-color);
    border: $hairline solid var(--request-method-delete-border-color);
  }

  .bqkVra,
  .dQCByP {
    padding: 1px 11px 1px 6px;
    box-sizing: border-box;
    background: var(--spec-page-endpoint-bg-color);
    border: 1px solid var(--spec-auth-input-border-color);
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--spec-page-endpoint-text-color) !important;
  }

  @media (min-width: $breakpoint-sm-mobile) and (max-width: $breakpoint-medium-mobile) {
    .try-out-style.bqkVra,
    .try-out-style.dQCByP {
      width: 60% !important;
    }
  }

  @media (min-width: $breakpoint-medium-mobile) and (max-width: $breakpoint-mid-one-mobile) {
    .try-out-style.bqkVra,
    .try-out-style.dQCByP {
      width: 70% !important;
    }
  }

  @media (min-width: $screen-xs) and (max-width: $screen-sm) {
    .try-out-style.bqkVra,
    .try-out-style.dQCByP {
      width: 75% !important;
    }
  }
  @media (min-width: $screen-sm) and (max-width: $breakpoint-ipad-landscap) {
    .try-out-style.bqkVra,
    .try-out-style.dQCByP {
      width: 85% !important;
    }
  }

  .try-out-style.bqkVra,
  .try-out-style.dQCByP {
    width: 73%;
    display: inline-block;
    position: relative;
    top: 7px;
  }

  .try-it-out-button {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    background-color: var(--spec-page-endpoint-trial-bg-color);
    border: $borderWidth solid var(--spec-page-endpoint-trial-bg-color);
    border-radius: unset;
    color: var(--spec-page-endpoint-text-color);
    transition: 0.3s;
    height: 42px;
    border-left: unset;
    border-right: unset;
    padding: spacer(3);
    text-align: left;

    &.open-form {
      border-bottom: 0;
    }
  }

  #send-button,
  #send-new-button {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.4375rem;
    border-radius: spacer(5);
    transition: 0.3s;
    background-color: var(--button-primary-background-color);
    border: $borderWidth solid var(--button-primary-border-color);
    color: var(--button-primary-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 100%;

    #send-button:hover {
      background-color: var(--button-primary-background-hover-color);
      color: var(--button-primary-text-hover-color);
    }
    a {
      color: $colorSupportingWhite;
    }

    &:not([disabled]):hover {
      background-color: var(--button-primary-background-hover-color);
      color: var(--button-primary-text-hover-color);

      &.-external::after {
        // background-image: url('#{$path-icons}/external-link-white.svg');
      }
    }
  }

  // Actual Response body
  .response-body {
    width: inherit !important;
    max-height: unset !important;
  }

  .response-header {
    background-color: var(--spec-page-response-bg-color) !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    height: 3.5rem;
  }

  .top-response-container {
    background-color: var(--spec-page-response-bg-color) !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    color: var(--body-text-color) !important;
    border-bottom: 2px var(--spec-page-response-bg-color) solid;

    .modal-button,
    .copy-response-button {
      color: var(--body-text-color) !important;

      &:hover {
        background-color: var(--redoc-expand-copy-hover-color) !important;
      }
    }
  }

  // Expand Modal
  .modal {
    background-color: $colorPrimaryBlack !important;
  }

  .modal-exit {
    background-color: $colorPrimaryGreen !important;
    border-top: $colorPrimaryBlack !important;
    border: $borderWidth solid $colorPrimaryGreen !important;

    &:hover {
      background-color: $colorPrimaryBlack !important;
      color: $colorSupportingWhite !important;
    }
  }

  // Target URL
  .target-url {
    @extend .redoc-input;
    height: unset !important;
    line-height: unset !important;
    color: var(--spec-page-endpoint-text-color) !important;
    span {
      color: var(--spec-page-endpoint-text-color) !important;
    }
  }

  // Response code section
  .react-tabs__tab-panel {
    background-color: var(--spec-page-request-sample-bg-color) !important;
    .gHKDTR {
      color: $colorWpSecondaryBodyTextColor;
    }
    .dZVfgH {
      button {
        color: $colorWpSecondaryBodyTextColor;
      }
    }

    //Dropdown section start
    .hNPkeD,
    .jHNdPc {
      background-color: var(--spec-auth-input-background-color);
      border: 1px solid var(--spec-auth-input-border-focus-color);
      border-radius: 4px;

      label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: var(--spec-auth-input-color);
      }

      select {
        padding: spacer(2);
        background-color: var(--spec-auth-input-background-color);
        border: 1px solid var(--spec-auth-input-border-focus-color);

        option {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: var(--spec-auth-input-color);
        }
      }
    }

    .emaLhv polyline {
      color: var(--spec-auth-input-color) !important;
    }

    .iAiRPc .fIbGVw,
    .dLjCiU .gHKDTR {
      position: static;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: var(--spec-page-example-text-color);
      margin-bottom: 5px;
      display: inherit;
    }

    //Dropdown section end
  }

  // Content type title
  .dpMbau {
    color: $colorSupportingWhite !important;
  }

  // Curly brace background
  .redoc-json {
    code {
      background-color: var(--spec-page-request-sample-code-bg-color) !important;
      border: none !important;
    }
  }

  .hMXgsB .hoverable {
    color: $colorSupportingWhite !important;
  }

  .collapser,
  .punctuation {
    color: $colorSupportingWhite !important;
  }

  // Response tabs
  .tab-error {
    background-color: var(--redoc-tab-error-color) !important;
    color: $colorPrimaryBlack !important;
  }

  .tab-success {
    background-color: $colorAccentLightCoolGreen !important;
    color: $colorPrimaryBlack !important;
  }

  .react-tabs__tab--selected {
    background-color: var(--redoc-tab-selected-color) !important;
    color: $colorPrimaryBlack !important;
  }

  .eGXVfd > ul > li,
  .loHuJX > ul > li {
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif !important;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 24px;
    border-radius: 2px;
    border: none;
  }

  .eGXVfd > ul > li.react-tabs__tab--selected:focus,
  .loHuJX > ul > li.react-tabs__tab--selected:focus {
    outline: none;
  }

  .eGXVfd > ul > li:only-child,
  .loHuJX > ul > li:only-child {
    min-width: auto;
  }

  // Request content type background
  .lcundD,
  .gIaJDv,
  .fMkjzE {
    color: var(--spec-page-request-sample-type-color) !important;
  }

  // Search Bar
  .search-input {
    color: var(--spec-left-nav-search-text-color) !important;
    &::placeholder {
      color: var(--spec-left-nav-search-text-color);
    }
    &::-ms-input-placeholder {
      color: var(--spec-left-nav-search-text-color);
    }
  }

  .sc-cLQEGU .bdBswK {
    background-color: $colorShadePrimaryBlackS20 !important;
  }

  .eWtOBi {
    background-color: $colorShadePrimaryBlackS20 !important;
  }

  .bdBswK {
    background-color: $colorShadePrimaryBlackS20 !important;
  }

  .hQYSSu {
    color: white !important;
    padding-top: 9px !important;
  }

  // Mobile-nav-button
  .iXutXb {
    left: 20px !important;
    bottom: 15px !important;
  }

  // Mobile-nav
  .dNVVZv {
    background-color: $colorShadePrimaryBlackS20 !important;
  }

  // Header parameters make text wrap
  .kKFAHm,
  .euawtR {
    word-break: break-all !important;
  }

  // // Main parent
  // .redoc-wrap {
  //   color: $colorSupportingWhite !important;
  // }
  // // Side navigation
  // .cTutD {
  //   background-color: $colorShadePrimaryBlackS20 !important;
  // }
  // // Header text
  // .dTJWQH  {
  //   color: $colorSupportingWhite !important;
  // }
  // // More header text
  // .WxWXp {
  //   color: $colorSupportingWhite !important;
  // }
  // // Authorization field
  // .auth-description {
  //   color: $colorSupportingWhite !important;
  // }
  // // Request header name text
  // .ioYTqA {
  //   color: $colorSupportingWhite !important;
  // }
  // // Download button
  // .jIdpVJ {
  //   color: $colorAccentGreen !important;
  //   border-color: $colorAccentGreen !important;
  //   :hover {
  //     color: $colorPrimaryGreen !important;
  //     border-color: $colorPrimaryGreen !important;
  //   }
  // }
  // Description links
  // .fhwBUC {
  //   p {
  //     a {
  //       color: $colorPrimaryGreen !important;
  //       :hover {
  //         color: $colorSupportingWhite !important;
  //       }
  //     }
  //   }
  // }
  .eHtzbE table tr {
    background-color: unset;
  }

  .eHtzbE table tr:nth-child(2n) {
    background-color: unset;
  }

  .kjegA,
  .hSPpdU,
  .qzlLd {
    p {
      a {
        color: $colorAccentLightBlue !important;

        &:hover {
          color: $colorSupportingWhite !important;
        }
      }
    }
  }

  // Section margin to align with header
  .huqNbJ:not(:last-of-type):after,
  .iIOsWt:not(:last-of-type):after {
    border-bottom: none;
  }

  .kiuZRK,
  .bqwgzE {
    background: var(--spec-page-thirdpane-bg-color);
  }

  .cjtbAK,
  .dtUibw,
  .fgXEJL,
  .DLGij,
  .iCCKfq,
  .kzDJPW {
    margin-top: 140px !important;
  }

  // Hide Search Bar
  .eWtOBi {
    display: none;
  }

  // Change background of Ace Editor
  .ace_content {
    background-color: var(--spec-page-editor-bg-color) !important;
  }

  .ceKEaT,
  .eIjWpQ {
    color: $colorAccentLightOrange !important;
    font-size: 14px;
  }

  .fVhULw,
  .iMxSBh {
    border-bottom: 1px solid var(--spec-page-table-text-td-border-bottom-color) !important;
  }
  .iMxSBh .qzlLd pre,
  .gaTxIt .wfptf pre,
  .fVhULw .hSPpdU pre {
    white-space: normal;
  }
  .cFtNKJ,
  .kWAXqC {
    border-left: 1px solid var(--spec-page-accent-color);
  }

  .golTRa,
  .cxCZSk {
    &::before {
      background: var(--spec-page-accent-color);
    }

    &::after {
      background: var(--spec-page-accent-color);
    }
  }

  tr:first-of-type > .sc-hAZoDl,
  tr:first-of-type > .sc-hBxehG {
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 22px,
      var(--spec-page-accent-color) 22px,
      var(--spec-page-accent-color) 100%
    );
  }

  tr.last > .sc-hAZoDl,
  tr.last > .sc-hBxehG {
    background-image: linear-gradient(
      to bottom,
      var(--spec-page-accent-color) 0%,
      var(--spec-page-accent-color) 22px,
      transparent 22px,
      transparent 100%
    );
  }

  .IKdbp,
  .eUDLAO {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .hSPpdU,
  .qzlLd {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .kKFAHm,
  .euawtR {
    padding-left: spacer(3);
  }

  .ZHRGo,
  .cTKaqp {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .bViVUD,
  .gmXjOb {
    color: var(--spec-page-accent-color) !important;
  }

  .sc-bjUoiL {
    padding-left: spacer(4);
  }

  .fVcICl {
    margin-left: spacerThree(3);
  }

  .kxssny {
    margin: auto;
  }

  .gjQuqF,
  .bvUgLx,
  .kAkGBK {
    margin-top: unset;
    width: 15px !important;
    height: 15px !important;
    margin-right: 0 !important;
  }

  .ldRhaj,
  .kAkGBK,
  .egNfF,
  .bGRTXn {
    margin-top: unset;
    width: 15px !important;
    height: 15px !important;
    margin-right: 0 !important;
  }

  .egNfF polygon {
    fill: var(--spec-page-endpoint-text-color) !important;
  }

  .gxNypP,
  .glxEld {
    background-color: $colorShadePrimaryBlackS40;
    padding-left: spacerThree(3);
    padding-right: spacerThree(3);
    position: relative;
  }

  .try-it-out-form {
    .label-name {
      font-weight: 600;
    }
  }

  .jatMGW,
  .gWtHaL {
    box-shadow: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .mntvZ,
  .btuEDW {
    background-color: var(--spec-page-endpoint-trial-bg-color);
    padding-left: spacerThree(3);
    padding-right: spacerThree(3);
    padding-bottom: 0;
    border: unset;
  }

  .mntvZ:focus,
  .btuEDW:focus {
    box-shadow: none;
  }

  .gxNypP:focus,
  .glxEld:focus {
    box-shadow: none;
  }

  .eNtdoU:focus-visible {
    background-color: $colorShadePrimaryBlackS40;
    border: unset;
    border: none;
    border-color: unset;
    outline: none !important;
  }

  .kEwVtc {
    flex-direction: row;
  }

  .hqupNN {
    margin-top: unset;
  }

  .kZJOCV {
    position: unset;
  }

  h5.iupIzr {
    border: 0;
  }

  table.iZJLLY {
    tr {
      &:last-child {
        td {
          border: 0;
        }
      }
    }
  }

  @media (max-width: $screen-tablet) {
    .ZHRGo,
    .cTKaqp {
      font-size: 13px;
    }

    .sc-bjUoiL {
      padding: 10px 0;
    }

    .fVhULw,
    .iMxSBh {
      border-left: unset;
      display: block;
    }

    .cFtNKJ {
      display: revert;
    }

    .hSPpdU,
    .qzlLd {
      font-size: 14px;
    }

    .gebTWV > tbody > tr {
      display: inherit;
    }

    .bViVUD {
      position: unset;
      left: unset;
    }
  }
  .bTjsxH,
  .ciSZlR {
    width: calc(100% - 300px) !important;
  }
  @media print, screen and (max-width: 50rem) {
    .kATJMa {
      flex-direction: row;
    }
    .bAtUrU {
      margin-top: 0;
    }
    .cBhgGW {
      -webkit-flex-direction: initial;
      -ms-flex-direction: initial;
      flex-direction: initial;
    }
    .gquJcA {
      margin-top: 0px;
      margin-left: 9px;
    }
    .bTjsxH,
    .ciSZlR {
      width: 100% !important;
    }
    .fVhULw,
    .iMxSBh {
      padding: 0 20px !important;
      border-bottom: none !important;
      border-left: 1px solid var(--spec-page-accent-color);
    }
    .cFtNKJ {
      display: block !important;
    }
  }
}

@media (max-width: $breakpoint-mid-two-mobile-max) {
  body .menu-content {
    top: 140px !important;
    height: calc(100vh - 140px) !important;
  }
}

app-header .header {
  z-index: 4 !important;
}

.hSPpdU p:last-child,
.qzlLd p:last-child {
  color: var(--spec-page-table-text-color);
  margin-bottom: 5px !important;
}

.ace-monokai .ace_entity.ace_name.ace_function,
.ace-monokai .ace_entity.ace_other,
.ace-monokai .ace_entity.ace_other.ace_attribute-name,
.ace-monokai .ace_variable {
  color: var(--spec-page-editor-variable-color);
}

.modal-exit {
  background-color: var(--spec-page-modal-bg-color) !important;
  border: $hairline solid var(--spec-page-modal-border-color) !important;
  color: var(--spec-page-modal-text-color) !important;
  margin-top: spacerThree(3);

  &:hover {
    background-color: var(--spec-page-modal-border-color) !important;
  }
}
