._pendo-text-plain {
  color: initial !important;
}

.pendo-radio {
  color: initial !important;
}

// descriptive text style
._pendo-descriptive-text {
  font-size: $font-size !important;
  line-height: 1.5rem !important;
  font-weight: 400 !important;
  color: var(--body-text-color) !important;
}

._pendo-title {
  font-size: 24px !important;
  font-weight: 900 !important;
  font-style: normal !important;
  color: var(--heading-color) !important;
}

._pendo-sub-title {
  font-size: 1.125rem !important;
  line-height: 1.5rem !important;
  font-weight: 600 !important;
  margin-bottom: unset !important;
  color: var(--secondary-masthead-title-color) !important;
}

._pendo-links {
  color: var(--link-color) !important;
  &:hover {
    color: var(--link-hover-color) !important;
    text-decoration: underline !important;
  }
}

._pendo-hr {
  border-color: var(--pg-section-border-color) !important;
}

._pendo-step-container-size {
  max-height: 600px !important;
}

// pendo container style
._pendo-step-container-styles {
  background-color: var(--fismodal-bg-color) !important;
  border: 0.0625rem solid var(--fismodal-border-color) !important;
  box-shadow: none !important;
  padding: 24px !important;
  color: var(--body-text-color) !important;
  max-height: 600px !important;
  overflow: auto !important;

  h2 {
    @extend ._pendo-title;
  }

  h3 {
    @extend ._pendo-sub-title;
  }

  p {
    @extend ._pendo-descriptive-text;
    span {
      @extend ._pendo-descriptive-text;
    }
  }

  a {
    @extend ._pendo-links;
  }

  hr {
    @extend ._pendo-hr;
  }

  select {
    color: var(--drop-down-font-color) !important;
    border: 0.0625rem solid var(--input-border-color) !important;
    padding-bottom: 6px !important;
    outline: none !important;
    background-color: var(--input-background-color) !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: 36px !important;
    padding: 12px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
    height: 45px !important;

    ::-webkit-input-placeholder {
      color: $colorAccentLightGray !important;
    }
    ::-moz-placeholder {
      color: $colorAccentLightGray !important;
    }
    ::-ms-placeholder {
      color: $colorAccentLightGray !important;
    }
    ::placeholder {
      color: $colorAccentLightGray !important;
    }
    .placeholder:first-child {
      color: $colorAccentLightGray !important;
    }
    select option:first-child {
      color: $colorAccentLightGray !important;
    }
  }

  textarea {
    color: var(--body-text-color) !important;
    border: 0.0625rem solid var(--input-border-color) !important;
    padding-bottom: 6px !important;
    outline: none !important;
    background-color: var(--input-background-color) !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: spacer(6) !important;
    font-size: 14px !important;
    border-radius: 5px !important;
    &:focus {
      border: 0.0625rem solid var(--input-focus-color) !important;
    }

    &::placeholder {
      color: var(--input-placeholder-text-color) !important;
      font-weight: normal;
    }
  }

  // pendo number scale choice style
  label.pendo-radio {
    color: var(--paginator-text-color) !important;
    background-color: var(--body-background-color) !important;
    border: $hairline solid var(--paginator-border-color) !important;
  }

  label.pendo-radio + div {
    color: var(--body-text-color) !important;
  }

  label.pendo-radio:hover {
    color: var(--paginator-active-text-color) !important;
    background-color: var(--paginator-active-hover-bg-color) !important;
    cursor: pointer !important;
  }

  input[type='radio'].pendo-radio:checked + label {
    color: var(--paginator-active-text-color) !important;
    background-color: var(--paginator-active-hover-bg-color) !important;
  }

  // pendo multi choice style
  ._pendo-multi-choice-poll-select-border {
    background-color: transparent !important;
    border: none !important;
  }

  // pendo buttons
  ._pendo-button {
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    line-height: 1.4375rem !important;
    border-radius: spacer(5) !important;
    user-select: none !important;
    display: inline-block !important;
    transition: 0.3s !important;
    background-color: var(--button-primary-background-color) !important;
    border: $borderWidth solid var(--button-primary-border-color) !important;
    color: var(--button-primary-text-color) !important;
    padding: calc(#{spacer(3)} - #{$borderWidth}) calc(#{spacer(6)} - #{$borderWidth}) !important;
    &:hover {
      background-color: var(--button-primary-background-hover-color) !important;
      color: var(--button-primary-text-hover-color) !important;
      border: $borderWidth solid var(--button-primary-background-hover-color) !important;
    }

    &._pendo-button-secondaryButton,
    &._pendo-button-tertiaryButton {
      color: var(--button-secondary-text-color) !important;
      background-color: transparent !important;
      border: $borderWidth solid var(-button-secondary-border-color) !important;
      &:hover {
        color: var(--button-secondary-text-hover-color) !important;
        background-color: var(--button-secondary-background-hover-color) !important;
      }
    }
  }
}

._pendo-resource-center-global-container {
  box-shadow: none !important;
  height: 430px !important;
  border: 1px solid var(--fismodal-border-color) !important;
  border-top-width: 2px !important;
  border-top-color: var(--button-primary-background-color) !important;
}

._pendo-resource-center-home-title-text {
  padding: 15px !important;
  text-align: left !important;
}

._pendo-resource-center-module-list-item-title-text {
  font-weight: bold !important;
}

._pendo-resource-center-view-container {
  height: 100%;
  overflow: hidden !important;
  border: unset !important;
  padding: 15px !important;
  #pendo-resource-center-module-title-container {
    border-color: var(--link-color) !important;
  }
  ._pendo-text-list-ordered {
    border-color: var(--fismodal-border-color) !important;
    padding: 0 !important;
    ._pendo-step-container-styles {
      background-color: var(--header-mega-dropdown-background-color) !important;
      border: 1px solid var(--card-product-border-color) !important;
      padding: 15px 20px 20px !important;
    }
  }

  ._pendo-text-list-item {
    cursor: pointer;
    padding: 15px !important;
    ._pendo-step-container-styles > div {
      color: var(--article-image-card-caption-color) !important;
    }
  }
}

._pendo-resource-center-view-container:focus-visible {
  outline: none !important;
}

._pendo-resource-center-home-list {
  ._pendo-text-list-item {
    border-color: var(--fismodal-border-color) !important;
  }
}

._pendo-resource-center-view-transitions {
  height: 430px !important;
  overflow: hidden;
}

._pendo-resource-center-view-transitions:focus-visible {
  outline: none !important;
}

._pendo-resource-center-close-button {
  font-family: 'Source Sans Pro' !important;
  color: var(--modal-icon-clor) !important;
  &:hover {
    color: var(--modal-icon-clor) !important;
  }
}

#pendo-close-guide-0c2179fe {
  color: var(--modal-icon-clor) !important;
  &:hover {
    color: var(--modal-icon-clor) !important;
  }
}

._pendo-simple-text {
  color: var(--article-image-card-caption-color) !important;
}

._pendo-resource-center-left-caret {
  color: var(--modal-icon-clor) !important;
}

._pendo-resource-center-left-caret:focus-visible {
  outline: none !important;
}
._pendo-resource-center-right-caret:focus-visible {
  outline: none !important;
}

.pendo-resource-center-badge-notification-bubble {
  top: 25px !important;
  height: 7px !important;
  width: 7px !important;
  background-color: #ff5c52 !important;
  color: #ff5c52 !important;
  border-radius: 7px !important;
  left: 10px !important;
}

.pendo-notification-bubble-unread-count {
  font-size: 4px !important;
  right: 5px !important;
  color: #ff5c52 !important;
  height: 1px !important;
  width: 1px !important;
}

.pendo-resource-center-badge-notification-bubble::before {
  // background-color: var(--paginator-active-hover-bg-color) !important;
  background-color: #ff5c52 !important;
}

.pendo-unread-announcement-mini-bubble {
  background-color: var(--paginator-active-hover-bg-color) !important;
}

._pendo-home-view-bubble {
  background-color: var(--paginator-active-hover-bg-color) !important;
  color: var(--paginator-active-text-color) !important;
}

._pendo-guide-walkthrough_ {
  ._pendo-open-text-poll-wrapper textarea {
    line-height: normal !important;
  }

  label.pendo-radio {
    border: none !important;
    color: var(--body-text-color) !important;
  }

  label.pendo-radio:hover {
    color: var(--body-text-color) !important;
    background-color: unset !important;
  }

  input[type='radio'].pendo-radio:checked + label {
    color: var(--body-text-color) !important;
    background-color: unset !important;
  }

  ._pendo-close-guide {
    color: var(--modal-icon-clor) !important;
    font-family: 'Source Sans Pro' !important;
  }
}

.-not-logged-in {
  .pendo-resource-center-badge-notification-bubble {
    display: none !important;
  }

  ._pendo-home-view-bubble {
    display: none !important;
  }
}
