app-search-window {
  .search {
    &.-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      display: flex;
      flex-direction: column;
      background-color: var(--search-page-background-color);
    }

    &.-search-title {
      @extend .-fontDisplaySourceSanBold;
      text-transform: none;
      color: var(--search-page-title-color);
    }

    &.-head {
      z-index: 2;
      padding: spacer(7) 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

      &-container {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 spacer(3);
      }
    }

    &.-input-wrapper {
      position: relative;

      input {
        width: 100% !important;
        margin-left: spacer(4) !important;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: $hairline solid var(--input-border-color) !important;
        padding-bottom: spacer(2) !important;
        outline: none !important;
        caret-color: var(--search-input-carret-color) !important;
        background-color: var(--input-background-color) !important;
        font-family: 'Source Sans Pro' !important;
        font-size: spacer(4) !important;
        font-weight: 600 !important;
        letter-spacing: 0 !important;
        line-height: spacer(6) !important;
        &::placeholder {
          color: var(--body-text-color);
        }
        &::-ms-input-placeholder {
          color: var(--body-text-color);
        }
      }
    }

    &.-input-close-icon {
      padding-top: spacer(2);
      border-bottom: $hairline solid var(--search-close-icon-border-bottom);
      icon {
        svg > * {
          fill: var(--search-text-color);
        }
        &:hover {
          svg > * {
            fill: var(--search-close-icon-hover-color);
          }
        }
      }
    }

    &.-search-icon {
      padding-top: spacer(2);
      icon {
        svg > * {
          fill: var(--search-text-color);
        }
      }
    }

    &.-close {
      padding: 0;
      border: 0;
      background-color: transparent;
      position: absolute;
      z-index: 3;
      display: flex;
      align-items: center;
      top: spacer(6);
      right: spacer(7);
      outline: none;

      &:hover {
        color: var(--search-close-icon-hover-color);
      }
    }

    &.-close-icon {
      margin-left: spacer(2);
    }

    &.-results {
      flex-grow: 1;
      overflow-y: auto;
      background-color: var(--search-page-background-color);
    }

    &.-results-inner {
      padding: spacer(4) 0 spacer(7);
    }
  }
}

@media (max-width: $screen-sm-max) {
  app-search-window {
    .search {
      &.-close {
        top: spacer(4);
        right: spacer(3);
      }

      &.-head {
        padding: spacer(7) 0;

        &-container {
          width: 100%;
          padding: 0 spacer(3);
        }
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  app-search-window {
    .search {
      &.-results-inner {
        padding: 50px 0 136px;
      }
    }
  }
}
